import Vue from 'vue'
const App = () => import('./App.vue')
import store from "./store"
import HighchartsVue from 'highcharts-vue'
import Clipboard from 'v-clipboard'
import VCalendar from 'v-calendar'
import MarqueeText from 'vue-marquee-text-component'

Vue.config.productionTip = false

import 'flag-icon-css/css/flag-icon.css'

Vue.use(HighchartsVue)
Vue.use(Clipboard)
Vue.use(VCalendar)
Vue.component('marquee-text', MarqueeText)

new Vue({
  render: h => h(App),
  store
}).$mount('#app')
