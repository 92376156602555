import Vue from "vue";
import RestService from "@/common/rest.service";
import {
    APP_INIT, DEPOSIT_REQUEST, DEPOSITS_GET, DOCUMENT_UPLOAD, DOCUMENTS_GET,
    LOGIN,
    LOGOUT, OTP_ACTIVATE, OTP_CREATE, OTP_DISABLE,
    PASSWORD_CHANGE, PASSWORD_RESET, PASSWORD_RESTORE,
    PERSONAL_UPDATE,
    REGISTER, TRANSACTIONS_GET, TRANSFER_REQUEST, WITHDRAWAL_REQUEST, WITHDRAWALS_GET
} from '@/store/actions.type'
import {
    USER,
    SYMBOL,
    SYMBOL_ACTIVE,
    MANAGERS,
    WITHDRAWALS,
    DOCUMENTS,
    DEPOSITS,
    NOTIFICATION,
    TOKEN,
    DEPOSIT_ADDRESS,
    MODAL,
    REFERRER,
    REGISTER_FORM,
    BLUR,
    PAGE,
    TRANSACTIONS, TRANSACTION, OTP_ENABLE_DATA, LOGIN_DATA, NOTIFICATION_ERROR, LOGIN_FAILED
} from '@/store/mutations.type'
import {SYMBOL_DEFAULT} from "@/common/config";
import {timeToString} from "@/common/helpers";

const state = {
    loggedIn: false,
    token: null,
    user: {
        fullname: ' ',
        manager_name: ' ',
        readonly: false
    },
    managers: [],
    transactions: [],
    deposits: [],
    withdrawals: [],
    documents: [],
    depositAddress: '',
    referrer: 0,
    registerForm: {
        email: '',
        password: ''
    },
    otpEnableData: {
        qr: '',
        base32: ''
    },
    loginData: {
        email: '',
        password: ''
    },
    loginFailed: false
}

const getters = {
    loggedIn() {
        return state.loggedIn
    },

    user() {
        return state.user
    },

    managers() {
        return state.managers
    },

    transactions() {
        return state.transactions
    },

    deposits() {
        return state.deposits
    },

    withdrawals() {
        return state.withdrawals
    },

    documents() {
        return state.documents
    },

    depositAddress() {
        return state.depositAddress
    },

    referrer() {
        return state.referrer
    },

    registerForm() {
        return state.registerForm
    },

    otpEnableData() {
        return state.otpEnableData
    },

    loginData() {
        return state.loginData
    },

    loginFailed() {
        return state.loginFailed
    }
}

const actions = {
    [APP_INIT]() {
        state.token = window.localStorage.getItem('token')

        if (state.token === undefined || state.token === 'undefined' || state.token === null || state.token === 'null') {
            window.localStorage.removeItem('token')

            RestService.get('/symbols/full')
                .then(symbols => {
                    for(let i in symbols) {
                        this.commit(SYMBOL, symbols[i])
                    }

                    if (this.getters.activeSymbol === '') {
                        this.commit(SYMBOL_ACTIVE, SYMBOL_DEFAULT)
                    }
                })
        } else {
            RestService.token(state.token)
            RestService.get('/user')
                .then(user => {
                    if (!user) {
                        window.localStorage.removeItem('token')
                    } else {
                        user.jwt_token = state.token
                        this.commit(USER, user)
                    }

                    RestService.get('/symbols/full')
                        .then(symbols => {
                            for(let i in symbols) {
                                this.commit(SYMBOL, symbols[i])
                            }

                            if (this.getters.activeSymbol === '') {
                                this.commit(SYMBOL_ACTIVE, SYMBOL_DEFAULT)
                            }
                        })
                })
        }

        RestService.get('/managers/list')
            .then(managers => {
                this.commit(MANAGERS, managers)
            })
    },

    [LOGIN](context, data) {
        if (data.email === undefined) {
            data.email = state.loginData.email
        }

        if (data.password === undefined) {
            data.password = state.loginData.password
        }

        RestService.post('/auth', data, (err) => {
            if (err === 'INVALID_CODE') {
                this.commit(LOGIN_DATA, {
                    email: data.email,
                    password: data.password
                })
            } else if (err === 'INVALID_LOGIN') {
                this.commit(LOGIN_FAILED, true)
            } else {
                this.commit(NOTIFICATION_ERROR, err)
            }
        })
            .then(user => {
                this.commit(LOGIN_DATA, {
                    email: '',
                    password: ''
                })

                RestService.token(user.jwt_token)
                window.localStorage.setItem('token', user.jwt_token)

                this.commit(USER, user)
            })
    },

    [PASSWORD_RESTORE](context, data) {
        RestService.post('/user/password', data)
            .then(() => {
                this.commit(NOTIFICATION, 'E-mail with the reset link has been sent to your e-mail address. Please check your inbox.')
            })
    },

    [PASSWORD_RESET](context, data) {
        RestService.post('/password/reset', {
            password: data.password,
            hash: window.localStorage.getItem('hash')
        })
            .then(() => {
                this.commit(NOTIFICATION, 'Password has been changed')
                this.commit(MODAL, {
                    login: true
                })
            })
    },

    [TOKEN](context, token) {
        RestService.token(token)
    },

    [LOGOUT]() {
        window.localStorage.removeItem('token')
        RestService.token('')

        this.commit(LOGOUT)
    },

    [REGISTER](context, data) {
        data.referrer = window.localStorage.getItem('referrer') || 0

        data.info = window.localStorage.getItem('track_code') || (data.info || '')

        RestService.post('/user', data)
            .then(() => {
                this.commit(NOTIFICATION, 'Account has been registered. You may login now')

                this.commit(MODAL, {
                    register: false,
                    login: true
                })

                this.commit(REGISTER_FORM, {
                    email: data.email,
                    password: data.password
                })

                window.history.pushState({}, '', '/?registration=success')
            })
    },

    [PERSONAL_UPDATE](context, user) {
        RestService.put('/user', user)
            .then(() => {
                this.commit(NOTIFICATION, 'Personal details have been updated')

                let nUser = {
                    ...state.user
                }

                for (let n in user) {
                    nUser[n] = user[n]
                }

                this.commit(USER, nUser)
            })
    },

    [PASSWORD_CHANGE](context, pass) {
        RestService.put('/user/password', pass)
            .then(() => {
                this.commit(NOTIFICATION, 'Password has been changed')
            })
    },

    [DEPOSITS_GET]() {
        RestService.get('/transactions/deposits')
            .then(deposits => {
                this.commit(DEPOSITS, deposits.rows)
            })
    },

    [WITHDRAWAL_REQUEST](context, data) {
        data.product = 'savings'
        data.balance = 'balance'
        RestService.post('/transactions/withdrawals', data)
            .then(() => {
                this.commit(NOTIFICATION, 'Withdrawal request has been sent')
                this.dispatch(WITHDRAWALS_GET)
            })
    },

    [WITHDRAWALS_GET]() {
        RestService.get('/transactions/withdrawals')
            .then(deposits => {
                this.commit(WITHDRAWALS, deposits.rows)
            })
    },

    [DOCUMENT_UPLOAD](context, data) {
        RestService.post('/kyc/documents', data)
            .then(() => {
                this.commit(NOTIFICATION, 'Document successfully uploaded')
                this.dispatch(DOCUMENTS_GET)
            })
    },

    [DOCUMENTS_GET]() {
        RestService.get('/kyc/documents')
            .then(documents => {
                this.commit(DOCUMENTS, documents.rows)
            })
    },

    [TRANSACTIONS_GET]() {
        RestService.get('/transactions')
            .then(transactions => {
                this.commit(TRANSACTIONS, transactions.rows)
            })
    },

    [TRANSFER_REQUEST](context, data) {
        RestService.post('/transactions/transfers', data)
            .then(() => {
                this.commit(MODAL, {
                    purchase: false
                })
                this.commit(BLUR, false)
                this.commit(PAGE, 'dashboard')
                this.commit(NOTIFICATION, 'Transfer has been processed')
            })
    },

    [DEPOSIT_REQUEST](context, data) {
        let win
        switch (data.psp) {
            case 'nax':
                RestService.get('/psp/nax/wallet', data)
                    .then(res => {
                        if (res.wallet) {
                            this.commit(DEPOSIT_ADDRESS, res.wallet)
                        }
                    })
                break
            case 'blockchain':
                RestService.post('/transactions/deposits', data)
                    .then(res => {
                        if (res.wallet) {
                            this.commit(DEPOSIT_ADDRESS, res.wallet)
                        }
                    })
                break
            case 'cc-ext':
                RestService.post('/transactions/deposits', data)
                    .then(() => {
                        this.commit(NOTIFICATION, 'Thank you! Your deposit is being processed')
                    })
                break
            case 'iframe-bitobit':
                win = window.open('https://bitobit.io/cc-to-btc/?ref_id=12164', '_blank')
                win.focus()
                break
        }
    },

    [OTP_CREATE]() {
        RestService.post('/otp')
            .then(data => {
                this.commit(OTP_ENABLE_DATA, data)
            })
    },

    [OTP_ACTIVATE](context, data) {
        RestService.put('/otp', {
            code: data.code
        })
            .then(() => {
                this.commit(NOTIFICATION, '2FA protection has been activated')
                this.commit(USER, {
                    otp: 1
                })
            })
    },

    [OTP_DISABLE](context, data) {
        RestService.post('/otp/remove', {
            code: data.code
        })
            .then(() => {
                this.commit(NOTIFICATION, '2FA protection has been disabled')

                this.dispatch(OTP_CREATE)
                this.commit(USER, {
                    otp: 0
                })
            })
    }
}

const mutations = {
    [USER](context, user) {
        state.user = user
        state.loggedIn = true
    },

    [LOGOUT]() {
        state.loggedIn = false
    },

    [MANAGERS](context, managers) {
        state.managers = managers
    },

    [DEPOSITS](context, deposits) {
        for(let i in deposits) {
            deposits[i].timeCreatedString = timeToString(deposits[i].time_created*1000)
        }
        deposits.reverse()
        Vue.set(state, 'deposits', deposits)
    },

    [WITHDRAWALS](context, withdrawals) {
        for(let i in withdrawals) {
            withdrawals[i].timeCreatedString = timeToString(withdrawals[i].time_created*1000)
        }
        withdrawals.reverse()
        Vue.set(state, 'withdrawals', withdrawals)
    },

    [TRANSACTIONS](context, transactions) {
        for(let i in transactions) {
            transactions[i].timeCreatedString = timeToString(transactions[i].time_created*1000)
        }
        transactions.reverse()
        Vue.set(state, 'transactions', transactions)
    },

    [TRANSACTION](context, transaction) {
        state.transactions.unshift(transaction)
    },

    [DOCUMENTS](context, documents) {
        for(let i in documents) {
            documents[i].timeCreatedString = timeToString(documents[i].time_created*1000)
        }
        documents.reverse()
        Vue.set(state, 'documents', documents)
    },

    [DEPOSIT_ADDRESS](context, address) {
        state.depositAddress = address
    },

    [REFERRER](context, val) {
        state.referrer = val

        window.localStorage.setItem('referrer', val || '0')
    },

    [REGISTER_FORM](context, form) {
        state.registerForm = {
            email: form.email,
            password: form.password
        }
    },

    [OTP_ENABLE_DATA](context, data) {
        state.otpEnableData = data
    },

    [LOGIN_DATA](context, data) {
        state.loginData.email = data.email
        state.loginData.password = data.password
    },

    [LOGIN_FAILED](context, val) {
        state.loginFailed = val
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
