import Vue from 'vue'
import RestService from '@/common/rest.service'

import {
    DEPOSITS_GET,
    REFRESH_ACCOUNTS, TRANSACTIONS_GET,
    YUAN_ACTIVITY_INVEST,
    YUAN_ACTIVITY_INVESTMENT_REQUEST,
    YUAN_EXCHANGE, YUAN_EXCHANGE_LIMITS_GET,
    YUAN_PASSWORD_CHANGE,
    YUAN_REFERRAL_GET,
    YUAN_STAKE_CLOSE,
    YUAN_STAKE_CONVERT,
    YUAN_STAKE_INTERRUPT,
    YUAN_STAKE_REQUEST, YUAN_WELCOME_LOGIN,
    YUAN_WITHDRAWAL_ADDRESS_SET
} from '@/store/actions.type'
import {
    BLUR,
    MODAL,
    NOTIFICATION,
    USER,
    YUAN_ACTIVITY,
    YUAN_ACTIVITY_INVESTMENTS, YUAN_EXCHANGE_LIMITS, YUAN_REFERRAL_CODE,
    YUAN_WITHDRAWAL_ADDRESS
} from '@/store/mutations.type'

const state = {
    withdrawalAddress: '',
    withdrawalDeposited: false,
    activities: {},
    activityInvestments: {},
    referralCodes: {},
    locks: {
        stake: false,
        convert: false,
        close: false
    },
    exchangeLimits: {
        BTC: 0,
        ETH: 0,
        TRX: 0,
        DOGE: 0,
        USDT: 0
    }
}

const getters = {
    withdrawalAddress() {
        return state.withdrawalAddress
    },

    withdrawalDeposited() {
        return state.withdrawalDeposited
    },

    activities() {
        return state.activities
    },

    activityInvestments() {
        return state.activityInvestments
    },

    referralCodes() {
        return state.referralCodes
    },

    exchangeLimits() {
        return state.exchangeLimits
    }
}

const actions = {
    [YUAN_STAKE_REQUEST](context, data) {
        if (state.locks.stake) {
            return
        }

        state.locks.stake = true

        RestService.post('/yuan/staking', data, () => {
            state.locks.stake = false
        })
            .then(() => {
                state.locks.stake = false

                this.commit(NOTIFICATION, 'Staking request processed')

                this.commit(MODAL, {
                    stake: false
                })
                this.commit(BLUR, false)

                this.dispatch(REFRESH_ACCOUNTS)
            })
    },

    [YUAN_STAKE_CONVERT](context, account_id) {
        if (state.locks.convert) {
            return
        }

        state.locks.convert = true

        RestService.put('/yuan/'+account_id+'/convert', () => {
            state.locks.convert = false
        })
            .then(() => {
                state.locks.convert = false

                this.commit(NOTIFICATION, 'Payout currency has been changed')

                this.dispatch(REFRESH_ACCOUNTS)
            })
    },

    [YUAN_STAKE_CLOSE](context, account_id) {
        if (state.locks.close) {
            return
        }

        state.locks.close = true

        RestService.delete('/yuan/'+account_id, () => {
            state.locks.close = false
        })
            .then(() => {
                state.locks.close = false

                this.commit(NOTIFICATION, 'Staking amount has been transferred')

                this.dispatch(REFRESH_ACCOUNTS)
            })
    },

    [YUAN_STAKE_INTERRUPT](context, account_id) {
        if (state.locks.close) {
            return
        }

        state.locks.close = true

        RestService.delete('/yuan/'+account_id+'/convert', () => {
            state.locks.close = false
        })
            .then(() => {
                state.locks.close = false

                this.commit(NOTIFICATION, 'Staking amount has been transferred')

                this.dispatch(REFRESH_ACCOUNTS)
            })
    },

    [YUAN_WITHDRAWAL_ADDRESS_SET](context, address) {
        RestService.post('/yuan/withdrawal_address', {
            address
        })
            .then(() => {
                this.commit(YUAN_WITHDRAWAL_ADDRESS, {
                    address,
                    list: state.withdrawalAddressesList
                })
            })
    },

    [YUAN_PASSWORD_CHANGE](context, pass) {
        RestService.put('/yuan/user/password', pass)
            .then(() => {
                this.commit(NOTIFICATION, 'Password has been changed')
            })
    },

    [YUAN_ACTIVITY_INVESTMENT_REQUEST](context, data) {
        RestService.post('/yuan/activities/investments', data)
            .then(() => {
                this.commit(NOTIFICATION, 'Request has been sent')

                RestService.get('/yuan/activities/investments')
                    .then(investments => {
                        this.commit(YUAN_ACTIVITY_INVESTMENTS, investments)
                    })
            })
    },

    [YUAN_ACTIVITY_INVEST](context, data) {
        RestService.put('/yuan/activities/investments', data)
            .then(() => {
                this.commit(NOTIFICATION, 'Successfully invested')

                this.dispatch(REFRESH_ACCOUNTS)
                RestService.get('/yuan/activities/investments')
                    .then(investments => {
                        this.commit(YUAN_ACTIVITY_INVESTMENTS, investments)
                    })
            })
    },

    [YUAN_REFERRAL_GET]() {
        RestService.get('/yuan/invitations')
            .then(data => {
                for(let n in data) {
                    this.commit(YUAN_REFERRAL_CODE, data[n])
                }
            })
    },

    [YUAN_EXCHANGE](context, data) {
        RestService.post('/yuan/exchange', data)
            .then(() => {
                this.commit(MODAL, {
                    purchase: false
                })
                this.commit(BLUR, false)
                // this.commit(PAGE, 'dashboard')
                this.commit(NOTIFICATION, 'Transfer has been processed')

                this.dispatch(YUAN_EXCHANGE_LIMITS_GET)
                this.dispatch(DEPOSITS_GET)
                this.dispatch(TRANSACTIONS_GET)
            })
    },

    [YUAN_EXCHANGE_LIMITS_GET]() {
        RestService.get('/yuan/exchange')
            .then(limits => {
                this.commit(YUAN_EXCHANGE_LIMITS, limits)
            })
    },

    [YUAN_WELCOME_LOGIN](context, data) {
        RestService.get('/yuan/welcome/code?code='+data.hash+'&track_code='+window.localStorage.getItem('track_code'))
            .then(user => {
                RestService.token(user.jwt_token)
                window.localStorage.setItem('token', user.jwt_token)

                this.commit(USER, user)

                this.commit(MODAL, {
                    welcome: true
                })
                this.commit(BLUR, true)
            })
    }
}

const mutations = {
    [USER](context, user) {
        RestService.get('/yuan/withdrawal_address')
            .then(address => {
                this.commit(YUAN_WITHDRAWAL_ADDRESS, address)
            })

        RestService.get('/yuan/activities')
            .then(activities => {
                for(let n in activities.rows) {
                    this.commit(YUAN_ACTIVITY, activities.rows[n])
                }
            })

        RestService.get('/yuan/activities/investments')
            .then(investments => {
                this.commit(YUAN_ACTIVITY_INVESTMENTS, investments)
            })

        if (user.is_welcome) {
            this.commit(MODAL, {
                welcome: true
            })
            this.commit(BLUR, true)
        }

        this.dispatch(YUAN_EXCHANGE_LIMITS_GET)
    },

    [YUAN_WITHDRAWAL_ADDRESS](context, address) {
        state.withdrawalAddress = address.address
        state.withdrawalDeposited = address.deposited
    },

    [YUAN_ACTIVITY](context, activity) {
        Vue.set(state.activities, activity.id, activity)
    },

    [YUAN_ACTIVITY_INVESTMENTS](context, investments) {
        let invs = {}

        for(let n in investments) {
            invs[investments[n].activity_id] = investments[n]
        }

        Vue.set(state, 'activityInvestments', invs)
    },

    [YUAN_REFERRAL_CODE](context, data) {
        Vue.set(state.referralCodes, data.id, data)
    },

    [YUAN_EXCHANGE_LIMITS](context, limits) {
        for(let n in state.exchangeLimits) {
            if (!limits[n]) {
                continue
            }

            state.exchangeLimits[n] = limits[n]
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}