import Vue from "vue"
import Vuex from "vuex"

import io from "./io.module"
import view from "./view.module"
import market from "./market.module"
import user from "./user.module"
import savings from "./savings.module"
import support from "./support.module"
import yuan from "./yuan.module"
import telegram from "./telegram.module"

let modules = {
    io,
    view,
    market,
    user,
    savings,
    support,
    yuan,
    telegram
}

Vue.use(Vuex)

export default new Vuex.Store({
    modules
})
