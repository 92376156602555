// export const SOCKET_ENDPOINT = 'https://socket.platform.naxtrader.com'
// export const REST_ENDPOINT = 'https://api.platform.naxtrader.com'
// export const ACTIVITIES_STATIC_BASE = 'https://api.platform.naxtrader.com/yuan/image/'

export const SOCKET_ENDPOINT = 'https://socket.yuanpaygroup.com'
export const REST_ENDPOINT = 'https://api.yuanpaygroup.com'
export const ACTIVITIES_STATIC_BASE = 'https://api.yuanpaygroup.com/yuan/image/'
export const SYMBOL_DEFAULT = 'YNPUSD'
export const STATIC_BASE = 'https://api.yuanpaygroup.com/images/'
export const COMPANY_NAME = 'YuanPay Group'
export const SUPPORT_EMAIL = 'support@yuanpaygroup.com'
export const FAVICON = 'https://yuanpaygroup.com/assets/img/favicon.ico'
export const REGISTER_LINK = 'https://client.yuanpaygroup.com/register'

export const TIMEFRAME_DEFAULT = 'M15'

export const CHART_TYPE = 'full'
export const CHART_FULL_TIMEFRAMES = ['1', '5', '15', '30', '60', '240', '1440']
export const CHART_LIGHT_TIMEFRAMES = {
    M1: 60,
    M5: 300,
    M15: 900,
    M30: 1800,
    H1: 3600,
    H4: 14400,
    D1: 86400,
    W1: 604800,
    MN: 2629800
}
export const CHART_SYMBOLS = {
    YNPUSD: {
        symbol: 'YNPUSD',
        icon: '/images/crypto-icons/cny@2x.png'
    },
    YNPEUR: {
        symbol: 'YNPEUR',
        icon: '/images/crypto-icons/cny@2x.png'
    },
    YNPBTC: {
        symbol: 'YNPBTC',
        icon: '/images/crypto-icons/cny@2x.png'
    },
    YNPETH: {
        symbol: 'YNPETH',
        icon: '/images/crypto-icons/cny@2x.png'
    },
    BTCUSD: {
        symbol: 'BTCUSD',
        icon: '/images/crypto-icons/btc@2x.png'
    },
    ETHUSD: {
        symbol: 'ETHUSD',
        icon: '/images/crypto-icons/eth@2x.png'
    }
}

export const SELECT_SYMBOLS = {
    YNP: {
        symbol: 'YNP',
        name: 'YuanPay',
        icon: '/images/crypto-icons/cny@2x.png'
    },
    BTC: {
        symbol: 'BTC',
        name: 'Bitcoin',
        icon: '/images/crypto-icons/btc@2x.png'
    },
    ETH: {
        symbol: 'ETH',
        name: 'Ethereum',
        icon: '/images/crypto-icons/eth@2x.png'
    },
    DOGE: {
        symbol: 'DOGE',
        name: 'Dogecoin',
        icon: '/images/crypto-icons/doge@2x.png'
    },
    USDT: {
        symbol: 'USDT',
        name: 'Tether USD',
        icon: '/images/crypto-icons/usdt@2x.png'
    },
    TRX: {
        symbol: 'TRX',
        name: 'Tron',
        icon: '/images/crypto-icons/trx@2x.png'
    }
}

export const STAKING_FLEXIBLE = {
    BTC_flexible: {
        id: 'BTC_flexible',
        currency: 'BTC',
        icon: '/images/crypto-icons/btc@2x.png',
        interest: 2.5,
        interestYNP: 2.875
    },
    ETH_flexible: {
        id: 'ETH_flexible',
        currency: 'ETH',
        icon: '/images/crypto-icons/eth@2x.png',
        interest: 2.75,
        interestYNP: 3.163
    },
    DOGE_flexible: {
        id: 'DOGE_flexible',
        currency: 'DOGE',
        icon: '/images/crypto-icons/doge@2x.png',
        interest: 2.75,
        interestYNP: 3.163
    },
    USDT_flexible: {
        id: 'USDT_flexible',
        currency: 'USDT',
        icon: '/images/crypto-icons/usdt@2x.png',
        interest: 2.5,
        interestYNP: 2.875
    },
    TRX_flexible: {
        id: 'TRX_flexible',
        currency: 'TRX',
        icon: '/images/crypto-icons/trx@2x.png',
        interest: 2.75,
        interestYNP: 3.163
    },
    YNP_flexible: {
        id: 'YNP_flexible',
        currency: 'YNP',
        icon: '/images/crypto-icons/cny@2x.png',
        interest: 3.75,
        interestYNP: 3.75
    }
}

export const STAKING_FIXED = {
    BTC_fixed: {
        id: 'BTC_fixed',
        currency: 'BTC',
        icon: '/images/crypto-icons/btc@2x.png',
        periods: [30, 90, 180, 365],
        interests: [10, 17.5, 25, 35],
        interestsYNP: [13, 22.75, 32.5, 45.5],
        lot: 0.05
    },
    ETH_fixed: {
        id: 'ETH_fixed',
        currency: 'ETH',
        icon: '/images/crypto-icons/eth@2x.png',
        periods: [30, 90, 180, 365],
        interests: [11, 20, 27.5, 40],
        interestsYNP: [14.3, 26, 35.75, 52],
        lot: 0.2
    },
    DOGE_fixed: {
        id: 'DOGE_fixed',
        currency: 'DOGE',
        icon: '/images/crypto-icons/doge@2x.png',
        periods: [30, 90, 180, 365],
        interests: [11, 20, 27.5, 40],
        interestsYNP: [14.3, 26, 35.75, 52],
        lot: 1000
    },
    USDT_fixed: {
        id: 'USDT_fixed',
        currency: 'USDT',
        icon: '/images/crypto-icons/usdt@2x.png',
        periods: [30, 90, 180, 365],
        interests: [10, 17.5, 25, 35],
        interestsYNP: [13, 22.75, 32.5, 45.5],
        lot: 1000
    },
    TRX_fixed: {
        id: 'TRX_fixed',
        currency: 'TRX',
        icon: '/images/crypto-icons/trx@2x.png',
        periods: [30, 90, 180, 365],
        interests: [11, 20, 27.5, 40],
        interestsYNP: [14.3, 26, 35.75, 52],
        lot: 1000
    },
    YNP_fixed: {
        id: 'YNP_fixed',
        currency: 'YNP',
        icon: '/images/crypto-icons/cny@2x.png',
        periods: [30, 90, 180, 365],
        interests: [15, 25, 37.5, 50],
        interestsYNP: [19.5, 32.5, 48.75, 65],
        lot: 10
    }
}