export const APP_INIT = 'appInit'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const REGISTER = 'register'
export const ORDER_OPEN_REQUEST = 'orderOpenRequest'
export const ORDER_MODIFY_REQUEST = 'orderModifyRequest'
export const ORDER_CLOSE_REQUEST = 'orderCloseRequest'
export const PERSONAL_UPDATE = 'personalUpdate'
export const PASSWORD_CHANGE = 'passwordChange'
export const PASSWORD_RESTORE = 'passwordRestore'
export const PASSWORD_RESET = 'passwordReset'
export const DEPOSIT_REQUEST = 'depositRequest'
export const DEPOSITS_GET = 'depositsGet'
export const WITHDRAWAL_REQUEST = 'withdrawalRequest'
export const WITHDRAWALS_GET = 'withdrawalsGet'
export const DOCUMENT_UPLOAD = 'documentUpload'
export const DOCUMENTS_GET = 'documentsGet'
export const TRANSACTIONS_GET = 'transactionsGet'
export const TRANSFER_REQUEST = 'transferRequest'
export const HIDE_DROPDOWNS = 'hideDropdowns'
export const PLAY_ERROR = 'playError'
export const PLAY_SUCCESS = 'playSuccess'
export const REFRESH_ACCOUNTS = 'refreshAccounts'

export const YUAN_STAKE_REQUEST = 'yuanStakeRequest'
export const YUAN_STAKE_CONVERT = 'yuanStakeConvert'
export const YUAN_STAKE_CLOSE = 'yuanStakeClose'
export const YUAN_STAKE_INTERRUPT = 'yuanStakeInterrupt'
export const YUAN_WITHDRAWAL_ADDRESS_SET = 'yuanWithdrawalAddressSet'
export const YUAN_PASSWORD_CHANGE = 'yuanPasswordChange'
export const YUAN_REFERRAL_GET = 'yuanReferralGet'

export const YUAN_ACTIVITY_INVESTMENT_REQUEST = 'yuanActivityInvestmentRequest'
export const YUAN_ACTIVITY_INVEST = 'yuanActivityInvest'
export const YUAN_EXCHANGE = 'yuanExchange'
export const YUAN_EXCHANGE_LIMITS_GET = 'yuanExchangeLimitsGet'

export const YUAN_WELCOME_LOGIN = 'yuanWelcomeLogin'

export const OTP_CREATE = 'otpCreate'
export const OTP_ACTIVATE = 'otpActivate'
export const OTP_DISABLE = 'otpDisable'

export const SUPPORT_SEND = 'supportSend'
export const SUPPORT_READ = 'supportRead'

export const TELEGRAM_LOGIN = 'telegramLogin'