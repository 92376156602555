import Vue from "vue"

import {
    ACCOUNT,
    BALANCE_UPDATE, CLEAR_ACCOUNTS, SYMBOL, SYMBOL_ACTIVE,
    USER
} from '@/store/mutations.type'
import RestService from "@/common/rest.service";
import {
    LOGOUT,
    REFRESH_ACCOUNTS
} from '@/store/actions.type'
import {currencySign} from "@/common/helpers";
import {SYMBOL_DEFAULT} from '@/common/config'


const state = {
    accounts: [],
    activeAccount: {
        id: 0,
        currency: 'BTC',
        currencySign: 'BTC ',
        balance: 0
    }
}

const getters = {
    accounts() {
        return state.accounts
    },

    activeAccount() {
        return state.activeAccount
    }
}

const actions = {
    [REFRESH_ACCOUNTS]() {
        this.commit(CLEAR_ACCOUNTS)
        RestService.get('/accounts')
            .then(data => {
                for(let i in data.accounts) {
                    this.commit(ACCOUNT, data.accounts[i])
                }
            })
    }
}

const mutations = {
    [USER]() {
        RestService.get('/accounts')
            .then(data => {
                for(let i in data.accounts) {
                    this.commit(ACCOUNT, data.accounts[i])
                }
            })
    },

    [CLEAR_ACCOUNTS]() {
        Vue.set(state, 'accounts', [])
        Vue.set(state, 'activeAccount',{
            id: 0,
            currency: 'BTC',
            currencySign: 'BTC ',
            balance: 0
        })
    },

    [ACCOUNT](context, account) {
        let found = false
        for(let i=0, l=state.accounts.length; i<l; i++) {
            if (state.accounts[i].product !== 'savings') {
                continue
            }

            if (state.accounts[i].id === account.id) {
                Vue.set(state.accounts, i, account)
                found = true
                break
            }
        }

        account.currencySign = currencySign(account.currency)

        if (!found) {
            state.accounts.push(account)
        }

        if (account.product === 'savings' && account.alias === 'BTC Wallet') {
            Vue.set(state, 'activeAccount', account)

            RestService.get('/symbols/full')
                .then(symbols => {
                    for(let i in symbols) {
                        this.commit(SYMBOL, symbols[i])
                    }

                    if (!this.getters.activeSymbol) {
                        this.commit(SYMBOL_ACTIVE, SYMBOL_DEFAULT)
                    }
                })
        }
    },

    [BALANCE_UPDATE](context, balance) {
        for(let i=0, l=state.accounts.length; i<l; i++) {
            if (balance.product === state.accounts[i].product && state.accounts[i].id === balance.account_id) {
                state.accounts[i].balance += balance.balanceChange
                state.accounts[i].credit += balance.creditChange
            }
        }
    },

    [LOGOUT]() {
        Vue.set(state, 'accounts',  [])
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
