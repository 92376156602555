export const maConfig = () => {
    return {
        name: 'Moving Average',
        type: 'indicator',
        extraPane: false
    }
}

export const maFull = (data, params, precision) => {
    let mAseries = [];

    let sum = 0;
    for(let i=0, l=data.length; i<l; i++) {
        sum += data[i].close;

        if (i >= params[0]) {
            sum -= data[i-params[0]].close;
        }

        if (i > params[0]) {
            mAseries.push({
                time: data[i].time,
                value: Number((sum / params[0]).toFixed(precision))
            })
        }
    }

    return [mAseries]
}

export const maLast = (data, params, precision) => {
    let sum = 0;
    for(let i=data.length-params[0], l=data.length-1; i<=l; i++) {
        sum += data[i].close;
    }

    return [{
        time: data[data.length-1].time,
        value: Number((sum / params[0]).toFixed(precision))
    }]
}

export const emaConfig = () => {
    return {
        name: 'EMA',
        type: 'indicator',
        extraPane: false
    }
}

export const emaFull = (data, params, precision) => {
    let mAseries = [];

    let ema = 0,
        mult = 1 / (1 + params[0])

    for(let i=0, l=data.length; i<l; i++) {
        ema = data[i].close * mult + ema * (1 - mult)

        if (i > params[0]) {
            mAseries.push({
                time: data[i].time,
                value: Number(ema.toFixed(precision))
            })
        }
    }

    return [mAseries]
}

export const emaLast = (data, params, precision) => {
    let ema = data[data.length-params[0]-1].close,
        mult = 1 / (1 + params[0])

    for(let i=data.length-params[0]-1, l=data.length-1; i<=l; i++) {
        ema = data[i].close * mult + ema * (1 - mult)
    }

    return [{
        time: data[data.length-1].time,
        value: Number(ema.toFixed(precision))
    }]
}

export const bollingerConfig = () => {
    return {
        name: 'Bollinger Bands',
        type: 'indicator',
        extraPane: false
    }
}

export const bollingerFull = (data, params, precision) => {
    let bBseries = [[], [], []];

    let sum = 0;
    let dev = 0;
    let bb = []
    for(let i=0, l=data.length; i<l; i++) {
        bb.push(Math.pow(data[i].close - sum / params[0], 2));
        dev += bb[bb.length-1];
        sum += data[i].close;

        if (i >= params[0]) {
            sum -= data[i-params[0]].close;
            dev -= bb[i-params[0]]
        }

        if (i > params[0]) {
            bBseries[0].push({
                time: data[i].time,
                value: (sum / params[0]).toFixed(precision)
            });
            bBseries[1].push({
                time: data[i].time,
                value: Number((sum / params[0] - params[1] * Math.sqrt(dev / (params[0] - 1))).toFixed(precision))
            });
            bBseries[2].push({
                time: data[i].time,
                value: Number((sum / params[0] + params[1] * Math.sqrt(dev / (params[0] - 1))).toFixed(precision))
            });
        }
    }

    return bBseries
}

export const bollingerLast = (data, params, precision) => {
    let sum = 0;
    for(let i=data.length-params[0], l=data.length-1; i<=l; i++) {
        sum += data[i].close;
    }

    let dev = 0,
        mean = sum / params[0];
    for(let i=data.length-params[0], l=data.length-1; i<=l; i++) {
        dev += Math.pow(data[i].close - mean, 2);
    }

    dev = Math.sqrt(dev / (params[0] - 1));

    return [{
        time: data[data.length-1].time,
        value: Number((sum / params[0]).toFixed(precision))
    }, {
        time: data[data.length-1].time,
        value: Number((sum / params[0] - params[1] * dev).toFixed(precision))
    }, {
        time: data[data.length-1].time,
        value: Number((sum / params[0] + params[1] * dev).toFixed(precision))
    }]
}

export const rsiConfig = () => {
    return {
        name: 'RSI',
        type: 'indicator',
        extraPane: true
    }
}

export const rsiFull = (data, params) => {
    let rsiSeries = [];

    let sumGain = 0,
        sumLoss = 0;

    for(let i=1, l=data.length; i<l; i++) {
        let diff = data[i].close - data[i-1].close;
        sumGain += diff > 0 ? diff : 0;
        sumLoss += diff < 0 ? Math.abs(diff) : 0;

        if (i > params[0]) {
            let oldDiff = data[i-params[0]].close - data[i-params[0]-1].close
            sumGain -= oldDiff > 0 ? oldDiff : 0;
            sumLoss -= oldDiff < 0 ? Math.abs(oldDiff) : 0;
        }

        if (i > params[0]) {
            rsiSeries.push({
                time: data[i].time,
                value: Number((100 - 100 / (1 + (sumGain / params[0])/(sumLoss / params[0]))).toFixed(2))
            })
        }
    }

    return [rsiSeries]
}

export const rsiLast = (data, params) => {
    let sumGain = 0,
        sumLoss = 0;
    for (let i = data.length - params[0], l = data.length - 1; i <= l; i++) {
        let diff = data[i].close - data[i - 1].close;

        sumGain += diff > 0 ? diff : 0;
        sumLoss += diff < 0 ? -diff : 0;
    }

    return [{
        time: data[data.length - 1].time,
        value: Number((100 - 100 / (1 + (sumGain / params[0]) / (sumLoss / params[0]))).toFixed(2))
    }]
}

export const stochasticConfig = () => {
    return {
        name: 'Stochastic Oscillator',
        type: 'indicator',
        extraPane: true
    }
}

export const stochasticFull = (data, params) => {
    let stSeries = [],
        high = data[0].close,
        low = data[0].close

    for(let i=0, l=data.length; i<l; i++) {
        let val = high - low === 0 ? 100 : 100 * (data[i].close - low)/(high - low)

        if (i > params[0]) {
            stSeries.push({
                time: data[i].time,
                value: val
            })
        }

        high = Math.max(high, data[i].close)
        low = Math.min(low, data[i].close)
    }

    return [stSeries]
}

export const stochasticLast = (data, params) => {
    let high = null,
        low = null

    for (let i = data.length - params[0], l = data.length - 1; i <= l; i++) {
        high = high === null || high < data[i].close ? data[i].close : high
        low = low === null || low > data[i].close ? data[i].close : low
    }

    let val = high - low === 0 ? 100 : 100 * (data[data.length - 1].close - low)/(high - low)

    return [{
        time: data[data.length - 1].time,
        value: val
    }]
}

export const adxConfig = () => {
    return {
        name: 'Average Directional Index',
        type: 'indicator',
        extraPane: true
    }
}

export const adxFull = (data, params) => {
    let adxSeries = [],
        tr = [0],
        dmP = [0],
        dmM = [0]

    for(let i=1, l=data.length; i<l; i++) {
        tr.push(Math.max(data[i].high - data[i].low), Math.abs(data[i].high - data[i-1].close), Math.abs(data[i].low - data[i-1].close))
        dmP.push(Math.max(data[i].high - data[i-1].high, 0))
        dmM.push(Math.max(data[i-1].low - data[i].low, 0))
    }

    let atr = tr[0],
        dmPsum = 0,
        dmMsum = 0,
        adx = 0

    for(let i=1, l=data.length; i<l; i++) {
        atr += tr[i]
        dmPsum += dmP[i]
        dmMsum += dmM[i]

        if (i >= params[0]) {
            atr -= tr[i-params[0]]
            dmPsum -= dmP[i-params[0]]
            dmMsum -= dmM[i-params[0]]
        } else {
            continue
        }

        let cAtr = atr / params[0],
            diP = 100 * (dmPsum / params[0]) / cAtr,
            diM = 100 * (dmMsum / params[0]) / cAtr,
            dx = 100 * (diP - diM) / (diP + diM)

        adx = (adx * (params[0] - 1) + dx) / params[0]

        if (adx === Number.NEGATIVE_INFINITY) {
            adx = -100
        }

        if (adx === Number.POSITIVE_INFINITY) {
            adx = 100
        }

        if (isNaN(adx)) {
            adx = 0
        }

        if (i >= params[0]) {
            adxSeries.push({
                time: data[i].time,
                value: Number(adx.toFixed(2))
            })
        }
    }

    return [adxSeries]
}

export const adxLast = (data, params) => {
    let tr = [0],
        dmP = [0], dmM = [0]

    for(let i=data.length-params[0], l=data.length-1; i<=l; i++) {
        tr.push(Math.max(data[i].high - data[i].low), Math.abs(data[i].high - data[i-1].close), Math.abs(data[i].low - data[i-1].close))
        dmP.push(data[i].high - data[i-1].high)
        dmM.push(data[i-1].low - data[i].low)
    }

    let atr = 0,
        dmPsum = 0,
        dmMsum = 0,
        adx = 0

    for(let i=0, l=tr.length; i<l; i++) {
        atr += tr[i]
        dmPsum += dmP[i]
        dmMsum += dmM[i]

        if (i >= params[0]) {
            atr -= tr[i-params[0]]
            dmPsum -= dmP[i-params[0]]
            dmMsum -= dmM[i-params[0]]
        }

        let cAtr = atr / params[0],
            diP = (dmPsum / params[0]) / cAtr,
            diM = (dmMsum / params[0]) / cAtr,
            dx = 100 * (diP - diM) / (diP + diM)

        adx = (adx * (params[0] - 1) + dx) / params[0]
    }

    return [{
        time: data[data.length-1].time,
        value: Number(adx.toFixed(2))
    }]
}

export const atrConfig = () => {
    return {
        name: 'Average True Range',
        type: 'indicator',
        extraPane: true
    }
}

export const atrFull = (data, params, precision) => {
    let atrSeries = [],
        tr = [0]

    for(let i=1, l=data.length; i<l; i++) {
        tr.push(Math.max(data[i].high - data[i].low), Math.abs(data[i].high - data[i-1].close), Math.abs(data[i].low - data[i-1].close))
    }

    let sum = 0
    for(let i=1, l=data.length; i<l; i++) {
        sum += tr[i]

        if (i >= params[0]) {
            sum -= tr[i-params[0]]
        }

        if (i > params[0]) {
            atrSeries.push({
                time: data[i].time,
                value: Number((sum / params[0]).toFixed(precision))
            })
        }
    }

    return [atrSeries]
}

export const atrLast = (data, params, precision) => {
    let tr = []

    for(let i=data.length-params[0], l=data.length-1; i<=l; i++) {
        tr.push(Math.max(data[i].high - data[i].low), Math.abs(data[i].high - data[i-1].close), Math.abs(data[i].low - data[i-1].close))
    }

    let sum = 0
    for(let i=0, l=tr.length; i<l; i++) {
        sum += tr[i];
    }

    return [{
        time: data[data.length-1].time,
        value: Number((sum / params[0]).toFixed(precision))
    }]
}

export const fiboConfig = () => {
    return {
        name: 'Fibonacci levels',
        type: 'lines',
        extraPane: false
    }
}

export const fiboFull = (data, params, precision) => {
    let extremes = {
        min: {
            time: null,
            value: null
        },
        max: {
            time: null,
            value: null
        }
    }

    for(let n=Math.max(data.length-201, 0); n<data.length; n++) {
        if (extremes.min.value === null || data[n].low < extremes.min.value) {
            extremes.min.value = data[n].low
            extremes.min.time = data[n].time
        }

        if (extremes.max.value === null || data[n].high > extremes.max.value) {
            extremes.max.value = data[n].high
            extremes.max.time = data[n].time
        }
    }

    let lines = [],
        dif = extremes.max.value - extremes.min.value,
        dir = extremes.min.time > extremes.max.time ? 'down' : 'up',
        levels = [100, 78.6, 61.8, 50, 38.2, 23.6, 0],
        colors = ['#9400d3', '#4b0082', '#0000ff', '#00ff00', '#ffff00', '#ff7f00', '#ff0000'],
        start = Math.min(extremes.min.time, extremes.max.time)

    for(let l in levels) {
        lines.push({
            color: colors[l],
            from: {
                time: start,
                value: Number((dir === 'up' ? extremes.min.value + dif * levels[l] / 100 :  extremes.max.value - dif * levels[l]).toFixed(precision))
            },
            to: {
                time: Date.now() + 86400000,
                value: Number((dir === 'up' ? extremes.min.value + dif * levels[l] / 100 :  extremes.max.value - dif * levels[l]).toFixed(precision))
            }
        })
    }

    return lines
}

export const fiboLast = () => {
    return null
}

export const trendConfig = () => {
    return {
        name: 'Trend lines',
        type: 'lines',
        extraPane: false
    }
}

export const trendFull = (data, params, precision) => {
    let extremesLeft = {
            min: {
                time: null,
                value: null
            },
            max: {
                time: null,
                value: null
            }
        }, extremesRight = {
            min: {
                time: null,
                value: null
            },
            max: {
                time: null,
                value: null
            }
        },
        endTime = (data[1].time - data[0].time)*500

    for(let n=Math.max(data.length-101, 0); n<data.length; n++) {
        if (extremesRight.min.value === null || data[n].low < extremesRight.min.value) {
            extremesRight.min.value = data[n].low
            extremesRight.min.time = data[n].time
        }

        if (extremesRight.max.value === null || data[n].high > extremesRight.max.value) {
            extremesRight.max.value = data[n].high
            extremesRight.max.time = data[n].time
        }
    }

    for(let n=Math.max(data.length-201, 0); n<data.length-101; n++) {
        if (extremesLeft.min.value === null || data[n].low < extremesLeft.min.value) {
            extremesLeft.min.value = data[n].low
            extremesLeft.min.time = data[n].time
        }

        if (extremesLeft.max.value === null || data[n].high > extremesLeft.max.value) {
            extremesLeft.max.value = data[n].high
            extremesLeft.max.time = data[n].time
        }
    }

    return [{
        color: '#00ff00',
        from: {
            time: extremesLeft.max.time,
            value: Number((extremesLeft.max.value).toFixed(precision))
        },
        to: {
            time: extremesRight.max.time + endTime,
            value: Number((extremesRight.max.value + ((extremesRight.max.value - extremesLeft.max.value) * (extremesRight.max.time - extremesLeft.max.time)/endTime)).toFixed(precision))
        }
    }, {
        color: '#ff0000',
        from: {
            time: extremesLeft.min.time,
            value: Number((extremesLeft.min.value).toFixed(precision))
        },
        to: {
            time: extremesRight.min.time + endTime,
            value: Number((extremesRight.min.value + ((extremesRight.min.value - extremesLeft.min.value) * (extremesRight.min.time - extremesLeft.min.time)/endTime)).toFixed(precision))
        }
    }]
}

export const trendLast = () => {
    return null
}