export const SOCKET_CONNECTED = 'socketConnected'
export const LOGOUT = 'logout'
export const USER = 'user'
export const TOKEN = 'token'
export const ACCOUNT = 'account'
export const ACCOUNT_ACTIVE = 'accountActive'
export const REFERRER = 'referrer'
export const REGISTER_FORM = 'registerForm'
export const QUOTE = 'quote'
export const QUOTES = 'quotes'
export const SYMBOL = 'symbol'
export const ORDER = 'order'
export const ORDER_OPEN = 'orderOpen'
export const ORDER_CLOSE = 'orderClose'
export const ORDER_UPDATE = 'orderUpdate'
export const ORDER_ACTIVATE = 'orderActivate'
export const HISTORY = 'history'
export const BALANCE_UPDATE = 'balanceUpdate'
export const TRANSACTION = 'transaction'
export const SYMBOL_ACTIVE = 'symbolActive'
export const SYMBOL_FAVORITE = 'symbolFavorite'
export const ORDER_ACTIVE = 'orderActive'
export const TIMEFRAME = 'timeframe'
export const MODAL = 'modal'
export const MODAL_CABINET_TAB = 'modalCabinetTab'
export const MACRO_NEWS_COLLAPSED = 'macroNewsCollapsed'
export const DROPDOWN_MENU = 'dropdownMenu'
export const INDICATOR_ADD = 'indicatorAdd'
export const INDICATOR_REMOVE = 'indicatorRemove'
export const CHART_DATA = 'chartData'
export const CHART_LINE_DATA = 'chartLineData'
export const NOTIFICATION = 'notification'
export const NOTIFICATION_ERROR = 'notificationError'
export const NOTIFICATION_REMOVE = 'notificationRemove'
export const MANAGERS = 'managers'
export const DEPOSITS = 'deposits'
export const WITHDRAWALS = 'withdrawals'
export const DOCUMENTS = 'documents'
export const TRANSACTIONS = 'transactions'
export const LANG = 'lang'
export const THEME = 'theme'
export const DEPOSIT_ADDRESS = 'depositAddress'
export const PAGE = 'page'
export const BLUR = 'blur'
export const DISPLAY_CURRENCY = 'displayCurrency'
export const MODAL_STAKE_PLAN = 'modalStakePlan'

export const YUAN_WITHDRAWAL_ADDRESS = 'yuanWithdrawalAddress'
export const YUAN_ACTIVITY = 'yuanActivity'
export const YUAN_ACTIVITY_INVESTMENTS = 'yuanActivityInvestments'
export const YUAN_REFERRAL_CODE = 'yuanReferralCode'
export const YUAN_EXCHANGE_LIMITS = 'yuanExchangeLimits'

export const OTP_ENABLE_DATA = 'otpEnableData'
export const LOGIN_DATA = 'loginData'
export const LOGIN_FAILED = 'loginFailed'

export const SUPPORT_MESSAGE = 'supportMessage'
export const SUPPORT_UNREAD = 'supportUnread'

export const CLEAR_ACCOUNTS = 'clearAccounts'