import {TELEGRAM_LOGIN} from '@/store/actions.type'
import RestService from '@/common/rest.service'
import {USER} from '@/store/mutations.type'

const actions = {
    [TELEGRAM_LOGIN](context, data) {
        RestService.post('/telegram/login', data)
            .then(user => {
                RestService.token(user.jwt_token)

                this.commit(USER, user)
            })
    }
}

export default {
    actions
}