import Vue from 'vue'
import jwt from 'jsonwebtoken'
import {
    LANG,
    NOTIFICATION,
    SYMBOL_ACTIVE,
    SYMBOL_FAVORITE,
    USER,
    NOTIFICATION_REMOVE,
    NOTIFICATION_ERROR,
    SYMBOL,
    ORDER_ACTIVE,
    TRANSACTION,
    DROPDOWN_MENU,
    MODAL, PAGE, BLUR, DISPLAY_CURRENCY, MODAL_STAKE_PLAN
} from '@/store/mutations.type'
import {FAVICON, SYMBOL_DEFAULT} from "@/common/config"
import {APP_INIT, HIDE_DROPDOWNS, PLAY_ERROR, PLAY_SUCCESS, YUAN_WELCOME_LOGIN} from '@/store/actions.type'
import RestService from "@/common/rest.service"
import {errorToText} from "@/common/helpers"

const successSound = new Audio(require('@/assets/sounds/success.wav'))
const errorSound = new Audio(require('@/assets/sounds/error.wav'))

import langs from '@/common/langs'

const state = {
    symbolActive: '',
    orderActive: 0,
    symbolInit: '',
    symbolsFavorite: [],
    notificationId: 0,
    notifications: [],
    depositApproved: {
        currency: 'USD',
        amount: 0,
        id: 0
    },
    modals: {
        purchase: false,
        stake: false,
        welcome: false
    },
    modalStakePlan: 'BTC_staking_3',
    dropdownMenuActive: false,
    langActive: 'gb',
    themeActive: 'dark',
    themeColors: {
        'background': '#336e9f',
        'background-dark': '#004c8d',
        'font': '#eeeeee',
        'brand': '#007bff',
        'green': '#409789',
        'red': '#bd7064',
        'blue': '#2267b0',
        'green-bright': '#00ff00',
        'red-bright': '#ff0000',
        'primary-button': '#007bff',
        'secondary-button': '#eeeeee',
        'even': '#1c1c1c',
        'odd': '#202020'
    },
    page: 'login',
    blur: false,
    displayCurrency: 'CNY'
}

const getters = {
    symbolActive() {
        return state.symbolActive
    },

    orderActive() {
        return state.orderActive
    },

    langActive() {
        return state.langActive
    },

    themeActive() {
        return state.themeActive
    },

    themeColors() {
        return state.themeColors
    },

    notification() {
        return state.notification
    },

    notifications() {
        return state.notifications
    },

    modals() {
        return state.modals
    },

    modalStakePlan() {
        return state.modalStakePlan
    },

    depositApproved() {
        return state.depositApproved
    },

    dropdownMenuActive() {
        return state.dropdownMenuActive
    },

    symbolsFavorite() {
        return state.symbolsFavorite
    },

    page() {
        return state.page
    },

    blur() {
        return state.blur
    },

    displayCurrency() {
        return state.displayCurrency
    },

    tokens() {
        return langs[state.langActive]
    }
}

const actions = {
    [APP_INIT]() {
        document.title = 'Yuan Pay Group'

        let link = document.createElement('link')
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        link.href = FAVICON

        document.getElementsByTagName('head')[0].appendChild(link)

        let path = window.location.search.slice(1).split('&')

        for(let n in path) {
            let t = path[n].split('='),
                param = t[0],
                val = t[1]

            switch (param) {
                case 'token':
                    try {
                        if (new Date().getTime() - jwt.decode(val).created*1000 < 1000*60*30) {
                            window.localStorage.setItem('token', val)
                        } else {
                            this.commit(NOTIFICATION_ERROR, 'INVALID_ACCOUNT')
                        }
                    } catch (e) {
                        this.commit(NOTIFICATION_ERROR, 'INVALID_ACCOUNT')
                    }

                    break
                case 'hash':
                    window.localStorage.setItem('hash', val)
                    break
                case 'invitation':
                    window.localStorage.setItem('invitation', val)
                    break
                case 'track_code':
                    window.localStorage.setItem('track_code', val)
                    break
                case 'welcome_hash':
                    window.localStorage.setItem('welcome_hash', val)
                    break
            }
        }

        state.symbolInit = SYMBOL_DEFAULT

        let toks = window.location.pathname.split('/')

        switch (toks[1]) {
            case 'deposit':
                this.commit(PAGE, 'deposit')
                break
            case 'register':
                this.commit(PAGE, 'register')
                break
            case 'password_reset':
                this.commit(PAGE, 'new-password')
                break
        }

        let favs = window.localStorage.getItem('symbolsFavorite')

        if (favs) {
            try {
                favs = JSON.parse(favs)
            } catch (e) {
                return
            }

            if (!Array.isArray(favs)) {
                return
            }

            favs.forEach(sym => {
                this.commit(SYMBOL_FAVORITE, {
                    symbol: sym,
                    action: 'add'
                })
            })
        }

        let lang = window.localStorage.getItem('langActive')

        if (lang) {
            this.commit(LANG, lang)
        }

        let displayCurrency = window.localStorage.getItem('displayCurrency')

        if (displayCurrency) {
            this.commit(DISPLAY_CURRENCY, displayCurrency)
        }

        RestService.err(err => {
            this.commit(NOTIFICATION_ERROR, err)
        })

        let welcome_hash = window.localStorage.getItem('welcome_hash')

        if (welcome_hash) {
            window.localStorage.removeItem('welcome_hash')

            this.dispatch(YUAN_WELCOME_LOGIN, {
                hash: welcome_hash
            })
        }
    },

    [HIDE_DROPDOWNS]() {
        this.commit(DROPDOWN_MENU, false)
    },

    [PLAY_ERROR]() {
        errorSound.play()
    },

    [PLAY_SUCCESS]() {
        successSound.play()
    }
}

const mutations = {
    [USER]() {
        if (state.page === 'login') {
            this.commit(PAGE, 'dashboard')
        }
    },

    [SYMBOL](context, symbol) {
        if (state.symbolInit && symbol.symbol === state.symbolInit) {
            this.commit(SYMBOL_ACTIVE, symbol.symbol)
            state.symbolInit = ''
        }
    },

    [SYMBOL_ACTIVE](context, symbol) {
        state.symbolActive = symbol
    },

    [ORDER_ACTIVE](context, order) {
        state.orderActive = order
    },

    [MODAL](context, data) {
        for(let name in data) {
            if (state.modals[name] !== undefined) {
                Vue.set(state.modals, name, data[name])
            }
        }

        // let active = false
        for(let n in state.modals) {
            if (state.modals[n]) {
                window.history.pushState({}, '', '/'+n)

                // active = true
                break
            }
        }

        // if (!active) {
        //     window.history.pushState({}, '', '/s/'+state.symbolActive)
        // }
    },

    [PAGE](context, val) {
        window.history.pushState({}, '', '/'+val)
    },

    [SYMBOL_FAVORITE](context, sym) {
        let index
        switch (sym.action) {
            case 'add':
                if (state.symbolsFavorite.indexOf(sym.symbol) === -1) {
                    state.symbolsFavorite.push(sym.symbol)

                    window.localStorage.setItem('symbolsFavorite', JSON.stringify(state.symbolsFavorite))
                }
                break
            case 'remove':
                index = state.symbolsFavorite.indexOf(sym.symbol)
                if (index === -1) {
                    return
                }

                state.symbolsFavorite.splice(index, 1)

                window.localStorage.setItem('symbolsFavorite', JSON.stringify(state.symbolsFavorite))

                Vue.set(state, 'symbolsFavorite', state.symbolsFavorite)
                break

        }
    },

    [NOTIFICATION](context, val) {
        let id = state.notificationId++,
        notification = {
            type: 'success',
            text: val,
            id
        }

        state.notifications.push(notification)
        setTimeout(() => {
            this.commit(NOTIFICATION_REMOVE, id)
        }, 4000)

        successSound.play()
    },

    [NOTIFICATION_ERROR](context, val) {
        let id = state.notificationId++,
        notification = {
            type: 'error',
            text: errorToText(val),
            id
        }

        state.notifications.push(notification)
        setTimeout(() => {
            this.commit(NOTIFICATION_REMOVE, id)
        }, 4000)

        errorSound.play()
    },

    [NOTIFICATION_REMOVE](context, id) {
        for(let n in state.notifications) {
            if (state.notifications[n].id === id) {
                state.notifications.splice(n, 1)
                break
            }
        }

        Vue.set(state, 'notifications', state.notifications)
    },

    [LANG](context, val) {
        state.langActive = val

        window.localStorage.setItem('langActive', val)
    },

    [PAGE](context, val) {
        state.page = val
    },

    [TRANSACTION](context, transaction) {
        if (transaction.status !== 1) {
            return
        }

        if (transaction.type !== 1 && transaction.type !== 2) {
            return
        }

        state.depositApproved = {
            id: transaction.id,
            amount: transaction.amount,
            currency: transaction.currency
        }

        this.commit(MODAL, {
            depositApproved: true
        })
    },

    [DROPDOWN_MENU](context, val) {
        state.dropdownMenuActive = val
    },

    [BLUR](context, val) {
        state.blur = val
    },

    [DISPLAY_CURRENCY](context, val) {
        state.displayCurrency = val

        window.localStorage.setItem('displayCurrency', val)
    },

    [MODAL_STAKE_PLAN](context, val) {
        state.modalStakePlan = val
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
