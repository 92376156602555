const langs = {
    gb: {
        Balance: 'Balance',
        Credit: 'Credit',
        Equity: 'Equity',
        Margin: 'Margin',
        MarginLevel: 'Margin Level',
        PnL: 'PnL',
        Account: 'Account',
        Register: 'Register',
        SignIn: 'Sign In',
        Login: 'Login',
        Password: 'Password',
        MarketWatch: 'Market Watch',
        All: 'All',
        ID: 'ID',
        Symbol: 'Symbol',
        Type: 'Type',
        Volume: 'Volume',
        OpenPrice: 'Open Price',
        OpenTime: 'Open Time',
        SL: 'Stop Loss',
        TP: 'Take Profit',
        Price: 'Price',
        Actions: 'Actions',
        Edit: 'Edit',
        Close: 'Close',
        ClosePrice: 'Close Price',
        CloseTime: 'Close Time',
        ClientArea: 'Client Area',
        Profile: 'Profile',
        Deposit: 'Deposit',
        Withdrawals: 'Withdrawals',
        Verification: 'Verification',
        Accounts: 'Accounts',
        ReferAFriend: 'Refer a Friend',
        Menu: 'Menu',
        ManageAccount: 'Manage Account',
        SwitchTheme: 'Switch Theme',
        LiveChat: 'Live Chat',
        LogOut: 'Log Out',
        Search: 'Search',
        Forex: 'Forex',
        Stocks: 'Stocks',
        Indices: 'Indices',
        Commodities: 'Commodities',
        Crypto: 'Crypto',
        TradingChart: 'Trading Chart',
        OpenOrder: 'Open Order',
        SELL: 'SELL',
        BUY: 'BUY',
        EconomicCalendar: 'Economic Calendar',
        Send: 'Send',
        OpenOrders: 'Open Orders',
        OrdersHistory: 'Orders History',
        TransferFunds: 'Transfer Funds',
        FromAccount: 'From Account',
        ToAccount: 'To Account',
        Amount: 'Amount',
        CurrentBalanceIs: 'Your current balance is',
        TransferAll: 'Transfer All',
        Transfer: 'Transfer',
        AccountsList: 'Accounts List',
        Product: 'Product',
        Group: 'Group',
        Leverage: 'Leverage',
        Currency: 'Currency',
        InterestRate: 'Interest Rate',
        Action: 'Action',
        Active: 'Active',
        MakeActive: 'Make Active',
        na: 'n/a',
        MakeDeposit: 'Make Deposit',
        Address: 'Address',
        DepositsHistory: 'Deposits History',
        Status: 'Status',
        Info: 'Info',
        StatusPending: 'Pending',
        StatusProcessed: 'Processed',
        StatusDeclined: 'Declined',
        StatusBeingProcessed: 'Being Processed',
        UploadedDocuments: 'Uploaded Documents',
        TimeUploaded: 'Time Uploaded',
        ProofOfId: 'Proof of Id',
        ProofOfAddress: 'Proof of Address',
        PersonalDetails: 'Personal Details',
        FirstName: 'First Name',
        LastName: 'Last Name',
        Email: 'Email',
        Phone: 'Phone',
        Country: 'Country',
        State: 'State / Region',
        FullAddress: 'Full Address',
        PostalCode: 'PostalCode',
        UpdatePersonalDetails: 'Update Personal Details',
        CurrentPassword: 'Current Password',
        NewPassword: 'New Password',
        NewReadonlyPassword: 'New Readonly Password (Optional)',
        ChangePassword: 'Change Password',
        UploadDocument: 'Upload Document',
        DocumentType: 'Document Type',
        File: 'File',
        Upload: 'Upload',
        RequestWithdrawal: 'Request Withdrawal',
        WithdrawAll: 'Withdraw all',
        Method: 'Method',
        BankTransfer: 'Bank Transfer',
        BitcoinTransfer: 'Bitcoin Transfer',
        BitcoinAddress: 'Bitcoin Address',
        YNPAddress: 'YuanPay Address',
        AccountNumber: 'Account Number',
        AccountHolder: 'Account Holder',
        IBAN: 'IBAN',
        SWIFT: 'SWIFT/BIC',
        DontHaveAccount: 'Still don\'t have an account?',
        SignUpNow: 'Sign Up Now',
        ForgotPassword: 'Forgot Password?',
        RestorePassword: 'Restore Password',
        PasswordConfirmation: 'Password Confirmation',
        SetNewPassword: 'Set New Password',
        OpenTradingAccount: 'Open Trading Account',
        Minimum8characters: 'Minimum 8 characters',
        AtLeastOneDigit: 'At least one digit',
        AtLeastOneLetter: 'At least one letter',
        NextStep: 'Next Step',
        Congratulations: 'Congratulations',
        ExplorePlatformBanner: 'Explore our award-winning platform or complete the registration to start trading',
        ExplorePlatform: 'Explore Platform',
        CompleteRegistration: 'Complete Registration',
        NewOrder: 'New Order',
        MarketOrder: 'Market',
        PendingOrder: 'Pending',
        lots: 'lots',
        ContractSize: 'Contract Size',
        Position: 'Position',
        triggerPrice: 'trigger price',
        MASystem: 'MA System',
        Strategy: 'Strategy',
        ConnectToAccount: 'Connect to Account',
        TradeSize: 'Trade Size',
        Recommended: 'Recommended',
        ConnectStrategy: 'Connect Strategy',
        SubscribedAccounts: 'Subscribed Accounts',
        Disconnect: 'Disconnect',
        PerformanceChart: 'Performance Chart',
        Last5signals: 'Last 5 signals',
        Instrument: 'Instrument',
        Time: 'Time',
        Direction: 'Direction',
        Buy: 'Buy',
        Sell: 'Sell',
        Yes: 'Yes',
        No: 'No',

        HelpAccounts: 'You can control your accounts and transfer the funds between them using this page. All transfers are processed immediately after submit. In order to receive additional information about your accounts feel free to contact our support team via e-mail ',
        HelpDeposit1: 'Please note that generated %currency% address is valid only for 1 hour. If you need more time to make a deposit, please request a new deposit when you will be ready to make a transfer.',
        HelpDeposit2: 'Deposits are processed automatically after receiving a confirmation from the payment system you choose. In order to receive additional information about deposit process feel free to contact our support team via e-mail support@yuanpaygroup.com',
        HelpVerification1: 'We accept files with following extensions: jpg, jpeg, png, gif. Please make sure that the size of the uploaded document is less than 2 Mb.',
        HelpVerification2: 'As per international requirements for KYC/AML policy, you should pass a verification process by uploading proofs of your identity and registration address using the form on this page. In order to receive additional information about verification process feel free to contact our support team via e-mail',
        HelpWithdrawal: 'All withdrawals are processed manually during the working hours of our support desk. Please don\'t send the repeating requests as it will not speed up the process. In order to receive additional information about withdrawal process feel free to contact our support team via e-mail',
        HelpMA: 'The automated strategy works on 4 major forex trading instruments: EUR/USD, GBP/USD, USD/JPY and USD/CHF. It uses the deep learning neural network, engineered and trained by the team of professional traders and analysts, to determine intraday patterns where price movement will happen with high probability and enters a trade. The short-term nature of trades allows to keep risks on low level while generating a stable profit each day.',

        Dashboard: 'Dashboard',
        MarginCall: 'Margin Call',
        LowMargin: 'Your margin level is low. Please make a deposit to avoid automatic liquidation of positions',
        TakeRisk: 'I take the risk',
        CreditCard: 'Credit Card',
        Bitcoin: 'Bitcoin',
        CreditCardNumber: 'Credit Card Number',
        CreditCardExpiration: 'Credit Card Expiration',
        CVV: 'CVV Code',
        Processing: 'Processing',
        Copy: 'Copy'
    },
    ru: {
        Balance: 'Баланс',
        Credit: 'Кредит',
        Equity: 'Средства',
        Margin: 'Маржа',
        MarginLevel: 'Уровень Маржи',
        PnL: 'Прибыль/Убыток',
        Account: 'Счет',
        Register: 'Зарегистрироваться',
        SignIn: 'Авторизоваться',
        Login: 'Войти',
        Password: 'Пароль',
        MarketWatch: 'Рынки',
        All: 'Все',
        ID: 'ID',
        Symbol: 'Инструмент',
        Type: 'Тип',
        Volume: 'Объем',
        OpenPrice: 'Цена Открытия',
        OpenTime: 'Время Открытия',
        SL: 'Стоп лосс',
        TP: 'Тейк профит',
        Price: 'Цена',
        Actions: 'Действия',
        Edit: 'Изменить',
        Close: 'Закрыть',
        ClosePrice: 'Цена Закрытия',
        CloseTime: 'Время Закрытия',
        ClientArea: 'Кабинет Клиента',
        Profile: 'Профиль',
        Deposit: 'Депозит',
        Withdrawals: 'Выводы',
        Verification: 'Верификация',
        Accounts: 'Счета',
        ReferAFriend: 'Пригласи Друга',
        Menu: 'Меню',
        ManageAccount: 'Управление Счетом',
        SwitchTheme: 'Изменить Оформление',
        LiveChat: 'Поддержка',
        LogOut: 'Выйти',
        Search: 'Поиск',
        Forex: 'Валюта',
        Stocks: 'Акции',
        Indices: 'Индексы',
        Commodities: 'Сырье',
        Crypto: 'Криптовалюта',
        TradingChart: 'Торговый График',
        OpenOrder: 'Новая Сделка',
        SELL: 'ПРОДАЖА',
        BUY: 'ПОКУПКА',
        EconomicCalendar: 'Экономический Календарь',
        Send: 'Отправить',
        OpenOrders: 'Текущие Сделки',
        OrdersHistory: 'История Сделок',
        TransferFunds: 'Перевод средств',
        FromAccount: 'Со счета',
        ToAccount: 'На счет',
        Amount: 'Сумма',
        CurrentBalanceIs: 'Ваш текущий баланс ',
        TransferAll: 'Перевести все',
        Transfer: 'Перевести',
        AccountsList: 'Список Счетов',
        Product: 'Продукт',
        Group: 'Группа',
        Leverage: 'Плечо',
        Currency: 'Валюта',
        InterestRate: 'Процентная Ставка',
        Action: 'Действие',
        Active: 'Активен',
        MakeActive: 'Сделать Активным',
        na: 'н/д',
        MakeDeposit: 'Пополнить Счет',
        Address: 'Адрес',
        DepositsHistory: 'История Пополнений',
        Status: 'Статус',
        Info: 'Информация',
        StatusPending: 'Новый',
        StatusProcessed: 'Одобрен',
        StatusDeclined: 'Отклонен',
        StatusBeingProcessed: 'В Обработке',
        UploadedDocuments: 'Загруженные Документы',
        TimeUploaded: 'Загружен',
        ProofOfId: 'Подтверждение личности',
        ProofOfAddress: 'Подтверждение адреса',
        PersonalDetails: 'Личные Данные',
        FirstName: 'Имя',
        LastName: 'Фамилия',
        Email: 'Электронная почта',
        Phone: 'Телефон',
        Country: 'Страна',
        State: 'Регион / Область / Штат',
        FullAddress: 'Полный Адрес',
        PostalCode: 'Индекс',
        UpdatePersonalDetails: 'Обновить Личные Данные',
        CurrentPassword: 'Текущий Пароль',
        NewPassword: 'Новый Пароль',
        NewReadonlyPassword: 'Новый Паролья для Чтения (Необязательно)',
        ChangePassword: 'Сменить Пароль',
        UploadDocument: 'Загрузить Документ',
        DocumentType: 'Тип Документа',
        File: 'Файл',
        Upload: 'Загрузить',
        RequestWithdrawal: 'Запросить Вывод Средств',
        WithdrawAll: 'Вывести все',
        Method: 'Метод',
        BankTransfer: 'Банковский Перевод',
        BitcoinTransfer: 'Bitcoin перевод',
        BitcoinAddress: 'Адрес Bitcoin',
        YNPAddress: 'Адрес YuanPay',
        AccountNumber: 'Номер Счета',
        AccountHolder: 'Владелец Счета',
        IBAN: 'IBAN',
        SWIFT: 'SWIFT/БИК',
        DontHaveAccount: 'Еще нет счета?',
        SignUpNow: 'Зарегистрироваться Сейчас',
        ForgotPassword: 'Забыли пароль?',
        RestorePassword: 'Восстановить Пароль',
        PasswordConfirmation: 'Подтверждение Пароля',
        SetNewPassword: 'Задать Новый Пароль',
        OpenTradingAccount: 'Открыть Торговый Счет',
        Minimum8characters: 'Минимум 8 символов',
        AtLeastOneDigit: 'Хотя бы одна цифра',
        AtLeastOneLetter: 'Хотя бы одна буква',
        NextStep: 'Следующий Шаг',
        Congratulations: 'Поздравляем',
        ExplorePlatformBanner: 'Познакомьтесь с нашей полнофункциональной платформой или завершите регистрацию для начала торговли',
        ExplorePlatform: 'Перейти к Платформе',
        CompleteRegistration: 'Завершить Регистрацию',
        NewOrder: 'Новая Сделка',
        MarketOrder: 'Рыночный',
        PendingOrder: 'Отложенный',
        lots: 'лотов',
        ContractSize: 'Размер Контракта',
        Position: 'Позиция',
        triggerPrice: 'цена активации',
        MASystem: 'Управляемый Счет',
        Strategy: 'Стратегия',
        ConnectToAccount: 'Подключить к Счету',
        TradeSize: 'Размер Сделок',
        Recommended: 'Рекомендовано',
        ConnectStrategy: 'Подключить Стратегию',
        SubscribedAccounts: 'Подключенные Счета',
        Disconnect: 'Отключить',
        PerformanceChart: 'График Результативности',
        Last5signals: 'Последние 5 сигналов',
        Instrument: 'Инструмент',
        Time: 'Время',
        Direction: 'Направление',
        Buy: 'Покупка',
        Sell: 'Продажа',
        Yes: 'Да',
        No: 'Нет',

        HelpAccounts: 'На этой странице вы можете управлять своими счетами и совершать переводы средств между ними. Все переводы обрабатываются автоматически и без задержек. Для получения дополнительной информации касательно ваших счетов, пожалуйста свяжитесь с нами, используя адрес электронной почты ',
        HelpDeposit1: 'Пожалуйста обратите внимание, что сгенерированный %currency% адрес является актуальным в течение 1 часа. Если вам требуется больше времени для совершения депозита, пожалуйста сгенерируйте новый адрес, когда будете готовы совершить перевод.',
        HelpDeposit2: 'Все депозиты обрабатываются в автоматическом режие сразу после получения подтверждения от выбранной вами платежной системы. Для получения дополнительной информации или помощи в пополнении счета, пожалуйста свяжитесь с нами, используя адрес электронной почты ',
        HelpVerification1: 'Вы можете загрузить файлы, имеющие один из следующих форматов: jpg, jpeg, png, gif. Пожалуйста, убедитесь, что загружаемый файл весит менее 2 мегабайт.',
        HelpVerification2: 'Согласно международным стандартам и требованиям в сфере KYC/AML политики, вы должны пройти процедуру верификации личности. Для этого вам необходимо загрузить по одному документу, подвтерждающему вашу личность и адрес проживания, используя форму ниже. Если у вас возникли вопросы по процедуре идентфикации личности, пожалуйста свяжитесь с нами, используя адрес электронной почты ',
        HelpWithdrawal: 'Все запросы на вывод средств обрабатываются в ручном режиме в рабочие часы финансового отдела. Пожалуйста, не создавайте повторных запросов на вывод средств, поскольку это не ускорит процесс. Для получения дополнительной информации по процессу вывода средств, пожалуйста свяжитесь с нами, используя адрес электронной почты ',
        HelpMA: 'Автоматизированная стратегия работает на 4 основных торговых парах: EUR/USD, GBP/USD, USD/JPY and USD/CHF. В основе алгоритма лежит нейронная сеть глубокого обучения, спроектированная и обученная командой профессиональных трейдеров и аналитиков. Система определяет шаблорнные движения цены и использует их для входа в позицию с высокой вероятностью прибыли. Краткосрочный тип сделок позволяет сохранять низкий уровень риска и генерировать стабильную ежедневную прибыль.',

        Dashboard: 'Обзор',
        MarginCall: 'Маржин Колл',
        LowMargin: 'Ваш счет имеет низкий уровень маржи. Пожалуйста, пополните счет во избежание принудительной ликвидации позиций',
        TakeRisk: 'Я принимаю риск',
        CreditCard: 'Кредитная Карта',
        Bitcoin: 'Биткоин',
        CreditCardNumber: 'Номер Карты',
        CreditCardExpiration: 'Срок Действия Карты',
        CVV: 'CVV Код (на обратной стороне)',
        Processing: 'Обработка',
        Copy: 'Копировать'
    },
    de: {
        Balance: 'Balance',
        Credit: 'Kredit',
        Equity: 'Mittel',
        Margin: 'Rendite',
        MarginLevel: 'Höhe der Rendite',
        PnL: 'Gewinn/Verlust',
        Account: 'Konto',
        Register: 'Registrieren',
        SignIn: 'Einloggen',
        Login: 'Anmelden',
        Password: 'Passwort',
        MarketWatch: 'Märkte',
        All: 'Alle',
        ID: 'ID',
        Symbol: 'Instrumente',
        Type: 'Typ',
        Volume: 'Volumen',
        OpenPrice:' Eröffnungspreis',
        OpenTime: 'Öffnungszeiten',
        SL: 'Stop Loss',
        TP: 'Take Profit',
        Price: 'Preis',
        Actions: 'Aktionen',
        Edit: 'bearbeiten',
        Close: 'schließen',
        ClosePrice: 'Endpreis',
        CloseTime:' Schließzeit',
        ClientArea: 'Kundenbereich',
        Profile: 'Profil',
        Deposit: 'Einzahlung',
        Withdrawals: 'Abbuchung',
        Verification: 'Verifizierung',
        Accounts: 'Konten',
        ReferAFriend: 'Lade einen Freund ein',
        Menu: 'Menü',
        ManageAccount: 'Konto verwalten',
        SwitchTheme: 'Erscheinungsbild ändern',
        LiveChat: 'Unterstützung',
        LogOut: 'Abmelden',
        Search: 'Suchen',
        Forex: 'Währung',
        Stocks: 'Aktien',
        Indices: 'Indizes',
        Commodities: 'Rohstoffe',
        Crypto: 'Kryptowährung',
        TradingChart: 'Handelsplan',
        OpenOrder: 'Neuer Geschäft',
        SELL: 'VERKAUF',
        BUY: 'KAUFEN',
        EconomicCalendar: 'Wirtschaftskalender',
        Send: 'Senden',
        OpenOrders: 'Aktuelle Angebote',
        OrdersHistory: 'Transaktionsverlauf',
        TransferFunds:' Geldtransfer',
        FromAccount: 'Vom Konto',
        ToAccount: 'Auf das Konto',
        Amount: 'Summe',
        CurrentBalanceIs: 'Ihr aktueller Saldo ',
        TransferAll: 'Alles überweisen',
        Transfer: 'Überweisen',
        AccountsList: 'Liste der Konten',
        Product: 'Produkt',
        Group: 'Gruppe',
        Leverage: 'Hebel',
        Currency: 'Währung',
        InterestRate: 'Zinssatz',
        Action: 'Aktion',
        Active: 'Aktiv',
        MakeActive: 'Aktiv machen',
        na: 'n/a',
        MakeDeposit: 'Einzahlen',
        Address: 'Adresse',
        DepositsHistory: 'Verlauf der Einzahlungen',
        Status: 'Status',
        Info: 'Info',
        StatusPending: 'Neu',
        StatusProcessed: 'genehmigt',
        StatusDeclined: 'abgelehnt',
        StatusBeingProcessed: 'In Bearbeitung',
        UploadedDocuments: 'Hochgeladene Dokumente',
        TimeUploaded: 'geladen',
        ProofOfId: 'Identitätsbestätigung',
        ProofOfAddress: 'Adresse bestätigen',
        PersonalDetails: 'Persönliche Daten',
        FirstName: 'Vorname',
        LastName: 'Nachname',
        Email: 'E-Mail',
        Phone: 'Telefon',
        Country: 'Land',
        State: 'Region / Gebiet / Staat',
        FullAddress: 'Vollständige Adresse',
        PostalCode: 'Postleitzahl',
        UpdatePersonalDetails: 'Persönliche Daten aktualisieren',
        CurrentPassword: 'Aktuelles Passwort',
        NewPassword: 'Neues Passwort',
        NewReadonlyPassword: 'Neues Lesekennwort (Optional)',
        ChangePassword: 'Passwort ändern',
        UploadDocument: 'Dokument hochladen',
        DocumentType: 'Dokumenttyp',
        File: 'Datei',
        Upload: 'hochladen',
        RequestWithdrawal: 'Auszahlungen anfordern',
        WithdrawAll: 'Alles abbuchen',
        Method: 'Methode',
        BankTransfer: 'Banküberweisung',
        BitcoinTransfer: 'Bitcoin Überweisung',
        BitcoinAddress: 'Bitcoin-Adresse',
        YNPAddress: 'YuanPay-Addresse',
        AccountNumber: 'Kontonummer',
        AccountHolder: 'Kontoinhaber',
        IBAN: 'IBAN',
        SWIFT: 'SWIFT/BIC',
        DontHaveAccount: 'Haben Sie noch kein Konto?',
        SignUpNow: 'Jetzt ein Konto anmelden',
        ForgotPassword: 'Passwort vergessen?',
        RestorePassword: 'Passwort wiederherstellen',
        PasswordConfirmation: 'Passwort bestätigen',
        SetNewPassword: 'Neues Passwort festlegen',
        OpenTradingAccount: 'Handelskonto eröffnen',
        Minimum8characters: 'Es muss mindestens 8 Zeichen sein',
        AtLeastOneDigit: 'Es muss mindestens eine Ziffer sein',
        AtLeastOneLetter: 'Es muss mindestens ein Buchstabe sein',
        NeXTStep: 'Nächster Schritt',
        Congratulations: 'Herzlichen Glückwunsch',
        ExplorePlatformBanner: 'Lernen Sie unsere voll funktionsfähige Plattform kennen oder schließen Sie Ihre Registrierung ab, um mit dem Handel zu beginnen',
        ExplorePlatform: 'Gehen Sie zur Plattform',
        CompleteRegistration: 'Registrierung abschließen',
        NewOrder: 'Neuer Geschäft',
        MarketOrder: 'Market',
        PendingOrder: 'Zurückgestellte',
        lots: 'lose',
        ContractSize: 'Vertragsgröße',
        Position: 'Position',
        triggerPrice: 'Aktivierungspreis',
        MASystem: 'Verwaltetes Konto',
        Strategy: 'Strategie',
        ConnectToAccount: 'Mit einem Konto verbinden sein',
        TradeSize: 'Größe des Geschäfts',
        Recommended: 'Empfohlen',
        ConnectStrategy: 'Strategie einschalten',
        SubscribedAccounts:'Verbundene Konten',
        Disconnect: 'deaktivieren',
        PerformanceChart: 'Leistungsdiagramm',
        Last5signals: 'Letzte 5 Zeichen',
        Instrument: 'Instrument',
        Time: 'Zeit',
        Direction: 'Richtung',
        Buy: 'Kaufen',
        Sell: 'Verkauf',
        Yes: 'Ja',
        No: 'Nein',

        HelpAccounts: 'Auf dieser Seite können Sie Ihre Konten verwalten und Überweisungen zwischen den Konten vornehmen. Alle Überweisungen werden automatisch und ohne Verzögerung verarbeitet. Für weitere Informationen zu Ihren Konten kontaktieren Sie uns bitte per E-Mail ',
        HelpDeposit1: 'Bitte beachten Sie, dass die generierte Adresse %currency% wird innerhalb von 1 Stunde aktuell. Wenn Sie mehr Zeit benötigen, um eine Einzahlung zu tätigen, generieren Sie bitte eine neue Adresse, wenn Sie bereit sind eine Überweisung zu tätigen.',
        HelpDeposit2: 'Alle Einzahlungen werden automatisch bearbeitet, sobald Sie eine Bestätigung von Ihrem gewählten Zahlungssystem erhalten. Wenn Sie weitere Informationen oder Hilfe bei der Einzahlung benötigen, kontaktieren Sie uns bitte per E-Mail ',
        HelpVerification1: 'Sie können Dateien mit einem der folgenden Formate hochladen: jpg, jpeg, png, gif. Bitte stellen Sie sicher, dass die hochgeladene Datei weniger als 2 Megabyte wiegt.',
        HelpVerification2: 'Nach den internationalen Standards und Anforderungen in der KYC/AML-Politik, müssen Sie eine Identitätsprüfung durchführen. Um dies zu tun müssen Sie ein Dokument hochladen, dass Ihre Identität und Wohnsitzadresse bestätigt. Damit Sie es tun, müssen Sie das untenstehende Formular verwenden. Wenn Sie Fragen zur Identifizierung haben, kontaktieren Sie uns bitte über die E-Mail ',
        HelpWithdrawal: 'Alle Auszahlungsanfragen werden während der Geschäftszeiten der Finanzabteilung manuell bearbeitet. Bitte erstellen Sie keine wiederholten Auszahlungsanfragen, da dies den Prozess nicht beschleunigt. Für weitere Informationen zum Auszahlungsprozess kontaktieren Sie uns bitte über die E-Mail ',
        HelpMA: 'Die automatisierte Strategie funktioniert auf 4 Haupthandelspaaren: EUR/USD, GBP/USD, USD/JPY and USD/CHF. Der Algorithmus basiert sich auf einem neuronalen Deep Learning-Netzwerk, das von einem Team aus professionellen Händlern und Analysten entwickelt und trainiert wird. Das System bestimmt musterhafte Preisänderungen und benutzt es, um eine Position mit hoher Gewinnwahrscheinlichkeit zu betreten. Die kurzfristige Art der Transaktionen ermöglicht es Ihnen, ein geringes Risiko zu halten und einen stabilen täglichen Gewinn zu generieren.',

        Dashboard: 'Dashboard',
        MarginCall: 'Margin Call',
        LowMargin: 'Your margin level is low. Please make a deposit to avoid automatic liquidation of positions',
        TakeRisk: 'I take the risk',
        CreditCard: 'Credit Card',
        Bitcoin: 'Bitcoin',
        CreditCardNumber: 'Credit Card Number',
        CreditCardExpiration: 'Credit Card Expiration',
        CVV: 'CVV Code',
        Processing: 'Processing',
        Copy: 'Copy'
    },
    pl: {
        Balance: 'Saldo',
        Credit: 'Kredyt',
        Equity: 'Środki',
        Margin: 'Marża',
        MarginLevel: 'Wysokość Marży',
        PnL: 'Zysk/Strata',
        Account: 'Konto',
        Register: 'Zarejestruj się',
        SignIn: 'Autoryzacja',
        Login: 'Wejdź',
        Password: 'Hasło',
        MarketWatch: 'Rynki',
        All: 'Wszystkie',
        ID: 'ID',
        Symbol: 'Narzędzie',
        Type: 'Typ',
        Volume: 'Wolumin',
        OpenPrice: 'Cena Otwarcia',
        OpenTime: 'Czas Otwarcia',
        SL: 'Stop Loss',
        TP: 'Take Profit',
        Price: 'Cena',
        Actions: 'Działania',
        Edit: 'Zmień',
        Close: 'Zamknij',
        ClosePrice: 'Cena Zamknięcia',
        CloseTime: 'Czas Zamknięcia',
        ClientArea: 'Strefa Klienta',
        Profile: 'Profil',
        Deposit: 'Wpłata',
        Withdrawals: 'Wypłaty',
        Verification: 'Weryfikacja',
        Accounts: 'Konta',
        ReferAFriend: 'Zaproś Znajomego',
        Menu: 'Menu',
        ManageAccount: 'Zarządzanie Kontem',
        SwitchTheme: 'Zmień Wygląd',
        LiveChat: 'Wsparcie',
        LogOut: 'Wyjdź',
        Search: 'Szukaj',
        Forex: 'Waluta',
        Stocks: 'Akcje',
        Indices: 'Indeksy',
        Commodities: 'Surowce',
        Crypto: 'Kryptowaluta',
        TradingChart: 'Wykres Inwestycyjny',
        OpenOrder: 'Nowa Transakcja',
        SELL: 'SPRZEDAŻ',
        BUY: 'KUPNO',
        EconomicCalendar: 'Kalendarz Ekonomiczny',
        Send: 'Wyślij',
        OpenOrders: 'Bieżące Transakcje',
        OrdersHistory: 'Historia Transakcji',
        TransferFunds: 'Przelew środków',
        FromAccount: 'Z konta',
        ToAccount: 'Na konto',
        Amount: 'Kwota',
        CurrentBalanceIs: 'Twoje saldo bieżące',
        TransferAll: 'Przelej wszystko',
        Transfer: 'Przelej',
        AccountsList: 'Lista Kont',
        Product: 'Produkt',
        Group: 'Grupa',
        Leverage: 'Dźwignia',
        Currency: 'Waluta',
        InterestRate: 'Stawka Procentowa',
        Action: 'Działanie',
        Active: 'Aktywne',
        MakeActive: 'Aktywuj',
        na: 'nie dot.',
        MakeDeposit: 'Dokonaj Wpłaty',
        Address: 'Adres',
        DepositsHistory: 'Historia Wpłat',
        Status: 'Status',
        Info: 'Informacja',
        StatusPending: 'Nowy',
        StatusProcessed: 'Zatwierdzony',
        StatusDeclined: 'Odrzucony',
        StatusBeingProcessed: 'W Trakcie',
        UploadedDocuments: 'Wgrane Dokumenty',
        TimeUploaded: 'Wgrany',
        ProofOfId: 'Potwierdzenie tożsamości',
        ProofOfAddress: 'Potwierdzenie adresu',
        PersonalDetails: 'Dane Personalne',
        FirstName: 'Imię',
        LastName: 'Nazwisko',
        Email: 'E-mail',
        Phone: 'Telefon',
        Country: 'Kraj',
        State: 'Region / Obwód / Stan',
        FullAddress: 'Pełny Adres',
        PostalCode: 'Kod pocztowy',
        UpdatePersonalDetails: 'Aktualizuj Dane Personalne',
        CurrentPassword: 'Aktualne Hasło',
        NewPassword: 'Nowe Hasło',
        NewReadonlyPassword: 'Nowe Hasło do Odczytu (Nieobowiązkowe)',
        ChangePassword: 'Zmień Hasło',
        UploadDocument: 'Wgraj Dokument',
        DocumentType: 'Typ Dokumentu',
        File: 'Plik',
        Upload: 'Wgraj',
        RequestWithdrawal: 'Zleć Wypłatę Środków',
        WithdrawAll: 'Wypłać wszystko',
        Method: 'Metoda',
        BankTransfer: 'Przelew Bankowy',
        BitcoinTransfer: 'Przelew Bitcoin',
        BitcoinAddress: 'Adres Bitcoin',
        YNPAddress: 'Adres YuanPay',
        AccountNumber: 'Numer Konta',
        AccountHolder: 'Właściciel Konta',
        IBAN: 'IBAN',
        SWIFT: 'SWIFT',
        DontHaveAccount: 'Nie masz jeszcze konta?',
        SignUpNow: 'Zarejestruj się teraz',
        ForgotPassword: 'Zapomniałeś hasła?',
        RestorePassword: 'Przywracanie Hasła',
        PasswordConfirmation: 'Potwierdzenie Hasła',
        SetNewPassword: 'Ustaw Nowe Hasło',
        OpenTradingAccount: 'Otwórz Konto Handlowe',
        Minimum8characters: 'Minimum 8 znaków',
        AtLeastOneDigit: 'Przynajmniej jedna cyfra',
        AtLeastOneLetter: 'Przynajmniej jedna litera',
        NextStep: 'Następny Krok',
        Congratulations: 'Gratulacje',
        ExplorePlatformBanner: 'Zapoznaj się z naszą w pełni funkcjonalną platformą lub zakończ proces rejestracji, aby rozpocząć handel',
        ExplorePlatform: 'Przejdź do Platformy',
        CompleteRegistration: 'Zakończ Rejestrację',
        NewOrder: 'Nowa Transakcja',
        MarketOrder: 'Rynkowy',
        PendingOrder: 'Oczekujący',
        lots: 'lotów',
        ContractSize: 'Wielkość Kontraktu',
        Position: 'Pozycja',
        triggerPrice: 'trigger price',
        MASystem: 'Zarządzane Konto',
        Strategy: 'Strategia',
        ConnectToAccount: 'Podłącz do Konta',
        TradeSize: 'Wielkość Transakcji',
        Recommended: 'Polecane',
        ConnectStrategy: 'Podłącz Strategię',
        SubscribedAccounts: 'Podłączone Konta',
        Disconnect: 'Odłącz',
        PerformanceChart: 'Wykres Wydajności',
        Last5signals: '5 Ostatnich sygnałów',
        Instrument: 'Narzędzie',
        Time: 'Czas',
        Direction: 'Kierunek',
        Buy: 'Kup',
        Sell: 'Sprzedaj',
        Yes: 'Tak',
        No: 'Nie',
        HelpAccounts: 'Na tej stronie możesz zarządzać swoimi kontami i dokonywać między nimi przelewów. Wszystkie przelewy dokonywane są automatycznie i bez opóźnień. Aby otrzymać więcej informacji na temat swoich kont, prosimy o kontakt z nami pod adresem ',
        HelpDeposit1: 'Prosimy zwrócić uwagę, że wygenerowany adres %currency% pozostaje aktualny przez 1 godzinę. Jeśli potrzebujesz więcej czasu, aby dokonać wpłaty, prosimy o wygenerowanie nowego adresu, kiedy będziesz gotowy, aby dokonać przelewu.',
        HelpDeposit2: 'Wszystkie wpłaty obsługiwane są automatycznie od razu po otrzymaniu potwierdzenia z wybranego przez Ciebie systemu. Aby otrzymać więcej informacji na temat doładowania konta, prosimy o kontakt z nami pod adresem ',
        HelpVerification1: ' Możesz wgrać pliki w jednym z następujących formatów: jpg, jpeg, png, gif. Prosimy upewnić się, że przesyłany plik ma mniej, niż 2 megabajty.',
        HelpVerification2: ' Zgodnie z międzynarodowymi standardami i wymaganiami w sferze polityki KYX/AML, powinieneś przejść procedurę weryfikacji tożsamości. W tym celu należy przesłać po jednym dokumencie potwierdzającym Twoją tożsamość i adres zamieszkania, korzystając z formularza poniżej. Jeśli masz pytania dotyczące procedury identyfikacji tożsamości, prosimy o kontakt z nami pod adresem ',
        HelpWithdrawal: ' Wszystkie zlecenia wypłaty środków są obsługiwane w trybie rynkowym w godzinach pracy wydziału finansowego. Prosimy nie składać kolejnych zleceń wypłaty środków, ponieważ nie przyspieszy to procesu. Aby otrzymać dodatkowe informacje na temat procesu wypłaty środków, prosimy o kontakt z nami pod adresem ',
        HelpMA: ' Zautomatyzowana strategia działa w 4 podstawowych parach: EUR/USD, GBP/USD, USD/JPY i USD/CHF. Algorytm oparty jest o neuronową sieć głębokiej nauki, zaprojektowaną i przestudiowaną przez zespół traderów i analityków. System określa wzorcowe ruchy ceny i wykorzystuje je do wejścia na pozycję z wysokim prawdopodobieństwem zysku. Krótkoterminowy typ transakcji pozwala zachować niski poziom ryzyka i wygenerować stabilny codzienny zysk.',

        Dashboard: 'Dashboard',
        MarginCall: 'Margin Call',
        LowMargin: 'Your margin level is low. Please make a deposit to avoid automatic liquidation of positions',
        TakeRisk: 'I take the risk',
        CreditCard: 'Credit Card',
        Bitcoin: 'Bitcoin',
        CreditCardNumber: 'Credit Card Number',
        CreditCardExpiration: 'Credit Card Expiration',
        CVV: 'CVV Code',
        Processing: 'Processing',
        Copy: 'Copy'
    }
}

export default langs