import io from 'socket.io-client'

import { SOCKET_ENDPOINT } from "@/common/config"

import { APP_INIT } from '@/store/actions.type'
import {
    SOCKET_CONNECTED,
    USER,
    SYMBOL,
    ORDER_OPEN,
    ORDER_CLOSE,
    ORDER_UPDATE,
    ORDER_ACTIVATE,
    BALANCE_UPDATE,
    LOGOUT, QUOTES, TOKEN, TRANSACTION, SUPPORT_MESSAGE
} from '@/store/mutations.type'

let quotesPool = {}
let quotesInterval = null

const state = {
    socket: null,
    socketState: false,
    socketQuotesSubs: [],
    token: null
}

const getters = {
    socketState() {
        return state.socketState
    }
}

const actions = {
    [APP_INIT]() {
        state.socket = io(SOCKET_ENDPOINT)

        state.socket.on('connect', () => {
            this.commit(SOCKET_CONNECTED)
        })

        state.socket.on('reconnect', () => {
            this.commit(SOCKET_CONNECTED)
        })
    }
}

const mutations = {
    [SOCKET_CONNECTED]() {
        state.socketState = true

        if (quotesInterval === null) {
            quotesInterval = setInterval(() => {
                this.commit(QUOTES, quotesPool)

                quotesPool = {}
            }, 500)
        }

        for(let i=0, l=state.socketQuotesSubs.length; i<l; i++) {
            state.socket.removeAllListeners('quotes.'+state.socketQuotesSubs[i]).on('quotes.'+state.socketQuotesSubs[i], quote => {
                quotesPool[quote.symbol] = quote
            })
        }

        state.socket.removeAllListeners('order_open').on('order_open', order => {
            this.commit(ORDER_OPEN, order)
        })

        state.socket.removeAllListeners('order_close').on('order_close', order => {
            this.commit(ORDER_CLOSE, order)
        })

        state.socket.removeAllListeners('order_update').on('order_update', order => {
            this.commit(ORDER_UPDATE, order)
        })

        state.socket.removeAllListeners('order_activate').on('order_activate', order => {
            this.commit(ORDER_ACTIVATE, order)
        })

        state.socket.removeAllListeners('balance_update').on('balance_update', balance => {
            this.commit(BALANCE_UPDATE, balance)
        })

        state.socket.removeAllListeners('transaction').on('transaction', transaction => {
            this.commit(TRANSACTION, transaction)
        })

        state.socket.removeAllListeners('chat_message').on('chat_message', message => {
            this.commit(SUPPORT_MESSAGE, message)
        })

        for(let n in this.getters.symbols) {
            state.socket.emit('quotes.sub', {
                symbol: this.getters.symbols[n].symbol,
                group: this.getters.symbols[n].symbol.includes('YNP') ? 'crypto' : this.getters.symbols[n].group
            })
        }
    },

    [USER](context, user) {
        state.socketState = false

        state.socket.destroy()

        state.socket = io(SOCKET_ENDPOINT, {
            query: {
                Authorization: user.jwt_token
            }
        })

        state.socket.on('connect', () => {
            this.commit(SOCKET_CONNECTED)
        })

        state.socket.on('reconnect', () => {
            this.commit(SOCKET_CONNECTED)
        })
    },

    [TOKEN](context, token) {
        state.token = token
        state.socketState = false

        state.socket.destroy()

        state.socket = io(SOCKET_ENDPOINT, {
            query: {
                Authorization: token
            }
        })

        state.socket.on('connect', () => {
            this.commit(SOCKET_CONNECTED)
        })

        state.socket.on('reconnect', () => {
            this.commit(SOCKET_CONNECTED)
        })
    },

    [LOGOUT]() {
        state.socketState = false

        state.socket.destroy()

        state.socket = io(SOCKET_ENDPOINT, {})

        state.socket.on('connect', () => {
            this.commit(SOCKET_CONNECTED)
        })

        state.socket.on('reconnect', () => {
            this.commit(SOCKET_CONNECTED)
        })
    },

    [SYMBOL](context, symbol) {
        if (state.socketQuotesSubs.indexOf(symbol.symbol) === -1) {
            state.socketQuotesSubs.push(symbol.symbol)
        }

        if (state.socketState) {
            state.socket.removeAllListeners('quotes.'+symbol.symbol).on('quotes.'+symbol.symbol, quote => {
                quotesPool[quote.symbol] = quote
            })

            state.socket.emit('quotes.sub', {
                symbol: symbol.symbol,
                group: symbol.symbol.includes('YNP') ? 'crypto' : symbol.group
            })
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
