import Vue from "vue"

import {
    CHART_DATA, CHART_LINE_DATA, INDICATOR_ADD, INDICATOR_REMOVE, MODAL,
    QUOTE, QUOTES,
    SYMBOL,
    SYMBOL_ACTIVE, TIMEFRAME
} from "@/store/mutations.type"

import RestService from "@/common/rest.service";
import {TIMEFRAME_DEFAULT, CHART_LIGHT_TIMEFRAMES} from "@/common/config";

import * as indicators from './../common/indicators'

const state = {
    symbols: {},
    symbolGroups: [],
    chartData: [],
    chartLineData: [],
    indicatorData: [],
    indicators: [],
    timeframe: TIMEFRAME_DEFAULT,
    symActive: ''
}

const getters = {
    quote() {
        return state.symbols[state.symActive] && state.symbols[state.symActive].quote ? state.symbols[state.symActive].quote : {}
    },

    symbols() {
        return state.symbols
    },

    symbolGroups() {
        return state.symbolGroups
    },

    chartData() {
        return state.chartData
    },

    chartLineData() {
        return state.chartLineData
    },

    indicatorData() {
        return state.indicatorData
    },

    indicators() {
        return state.indicators
    }
}

const actions = {

}

const mutations = {
    [SYMBOL](context, symbol) {
        Vue.set(state.symbols, symbol.symbol, symbol)

        if (state.symbolGroups.indexOf(symbol.market) === -1) {
            state.symbolGroups.push(symbol.market)
        }
    },

    [QUOTES](context, quotes) {
        for(let i in quotes) {
            let quote = quotes[i]

            if (state.symbols[quote.symbol] === undefined) {
                return
            }

            let dirBid = quote.bid < state.symbols[quote.symbol].quote.bid ? 'down' : (quote.bid > state.symbols[quote.symbol].quote.bid ? 'up' : ''),
                dirAsk = quote.ask < state.symbols[quote.symbol].quote.ask ? 'down' : (quote.ask > state.symbols[quote.symbol].quote.ask ? 'up' : '')

            state.symbols[quote.symbol].quote = quote
            state.symbols[quote.symbol].quote.dirBid = dirBid
            state.symbols[quote.symbol].quote.dirAsk = dirAsk

            if (quote.symbol === state.symActive && state.chartData.length > 0) {
                let last = state.chartData[state.chartData.length-1],
                    t = Math.floor(Date.now() / (CHART_LIGHT_TIMEFRAMES[state.timeframe] * 1000)) * CHART_LIGHT_TIMEFRAMES[state.timeframe]

                if (last.time === t) {
                    if (last.high < quote.bid) {
                        last.high = quote.bid
                    }
                    if (last.low > quote.bid) {
                        last.low = quote.bid
                    }
                    last.close = quote.bid

                    state.chartLineData[state.chartLineData.length - 1].value = quote.bid

                    for(let i in state.indicators) {
                        let newVals = indicators[state.indicatorData[i].indicator+'Last'](state.chartLineData,  state.indicatorData[i].params,  state.indicatorData[i].precision)

                        for(let j in newVals) {
                            state.indicatorData[i].values[j][state.indicatorData[i].values[j].length - 1] = newVals[j]
                        }
                    }
                } else {
                    state.chartData.push({
                        time: t,
                        open: quote.bid,
                        high: quote.bid,
                        low: quote.bid,
                        close: quote.bid
                    })
                    state.chartLineData.push({
                        time: t,
                        value: quote.bid
                    })

                    for(let i in state.indicators) {
                        if (state.indicatorData[i].type === 'lines') {
                            continue
                        }
                        let newVals = indicators[state.indicatorData[i].indicator+'Last'](state.chartData,  state.indicatorData[i].params,  state.indicatorData[i].precision)

                        for(let j in newVals) {
                            state.indicatorData[i].values[j].push(indicators[state.indicatorData[i].indicator + 'Last'](state.chartData, state.indicatorData[i].params, state.indicatorData[i].precision))
                        }
                    }
                }
            }
        }
    },

    [QUOTE](context, quote) {
        if (state.symbols[quote.symbol] === undefined) {
            return
        }

        let dirBid = quote.bid < state.symbols[quote.symbol].quote.bid ? 'down' : (quote.bid > state.symbols[quote.symbol].quote.bid ? 'up' : ''),
            dirAsk = quote.ask < state.symbols[quote.symbol].quote.ask ? 'down' : (quote.ask > state.symbols[quote.symbol].quote.ask ? 'up' : '')

        state.symbols[quote.symbol].quote = quote
        state.symbols[quote.symbol].quote.dirBid = dirBid
        state.symbols[quote.symbol].quote.dirAsk = dirAsk

        if (quote.symbol === state.symActive && state.chartData.length > 0) {
            let last = state.chartData[state.chartData.length-1],
                t = Math.floor(Date.now() / (CHART_LIGHT_TIMEFRAMES[state.timeframe] * 1000)) * CHART_LIGHT_TIMEFRAMES[state.timeframe]

            if (last.time === t) {
                if (last.high < quote.bid) {
                    last.high = quote.bid
                }
                if (last.low > quote.bid) {
                    last.low = quote.bid
                }
                last.close = quote.bid

                state.chartLineData[state.chartLineData.length - 1].value = quote.bid

                for(let i in state.indicators) {
                    let newVals = indicators[state.indicatorData[i].indicator+'Last'](state.chartLineData,  state.indicatorData[i].params,  state.indicatorData[i].precision)

                    for(let j in newVals) {
                        state.indicatorData[i].values[j][state.indicatorData[i].values[j].length - 1] = newVals[j]
                    }
                }
            } else {
                state.chartData.push({
                    time: t,
                    open: quote.bid,
                    high: quote.bid,
                    low: quote.bid,
                    close: quote.bid
                })

                state.chartLineData.push({
                    time: t,
                    value: quote.bid
                })

                for(let i in state.indicators) {
                    let newVals = indicators[state.indicatorData[i].indicator+'Last'](state.chartLineData,  state.indicatorData[i].params,  state.indicatorData[i].precision)

                    for(let j in newVals) {
                        state.indicatorData[i].values[j].push(indicators[state.indicatorData[i].indicator + 'Last'](state.chartLineData, state.indicatorData[i].params, state.indicatorData[i].precision))
                    }
                }
            }
        }
    },

    [CHART_DATA](context, data) {
        state.chartData = data
    },

    [CHART_LINE_DATA](context, data) {
        state.chartLineData = data
    },

    [SYMBOL_ACTIVE](context, symbol) {
        state.chartData = []
        state.chartLineData = []

        state.symActive = symbol

        let req = {
            frame: CHART_LIGHT_TIMEFRAMES[state.timeframe],
            count: 1000
        }

        // if (this.getters.user.group) {
        //     req.group = this.getters.activeAccount.group
        // }

        req.group = state.symbols[symbol] && state.symbols[symbol].group ? state.symbols[symbol].group : ''

        RestService.get('/quotes/'+symbol+'/history', req)
            .then(ret => {
                let d = [],
                    l = []

                for(let n in ret) {
                    d.push({
                        time: ret[n][5],
                        open: ret[n][0],
                        high: ret[n][1],
                        low: ret[n][2],
                        close: ret[n][3]
                    })

                    l.push({
                        time: ret[n][5],
                        value: ret[n][3]
                    })
                }

                this.commit(CHART_DATA, d)
                this.commit(CHART_LINE_DATA, l)

                this.commit(MODAL, {
                    loader: false
                })
            })
    },

    [TIMEFRAME](context, frame) {
        state.timeframe = frame

        this.commit(SYMBOL_ACTIVE, state.symActive)
    },

    [INDICATOR_ADD](context, indicator) {
        state.indicators.push(indicator)

        let params = [indicator.period],
            conf = indicators[indicator.indicator+'Config']()

        indicator.name = conf.name
        indicator.extraPane = conf.extraPane
        indicator.type = conf.type

        for(let n in indicator.params) {
            params.push(indicator.params[n])
        }

        state.indicatorData.push({
            indicator: indicator.indicator,
            type: indicator.type,
            params,
            precision: state.symbols[state.symActive].precision,
            values: indicators[indicator.indicator+'Full'](state.chartData, params, state.symbols[state.symActive].precision)
        })
    },

    [INDICATOR_REMOVE](context, id) {
        state.indicators.splice(id, 1)

        state.indicatorData.splice(id, 1)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
